import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import ReturnConfirmationModal from "../components/returnConfirmationModal";

const IncomingBikes = () => {
  const [incomingBookingsData, setIncomingBookingsData] = useState([]);
  const [incomingReturnsData, setIncomingReturnsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [returnModalData, setReturnModalData] = useState(null); // State for return modal

  const toggleReturnModal = useCallback((data = null) => {
    if (data) {
      setReturnModalData({ ...data, source: "returns" });
    } else {
      setReturnModalData(null); // This should hide the modal
    }
  }, []);

  const updateField = useCallback(
    async (id, field, value, source) => {
      try {
        let url = `/api/${source}/${id}`;
        const response = await axios.put(url, { [field]: value });
        if (response.status === 200) {
          switch (source) {
            case "bookings":
              setIncomingBookingsData(
                incomingBookingsData.map((item) =>
                  item._id === id ? { ...item, [field]: value } : item
                )
              );
              break;

            case "returns":
              setIncomingReturnsData(
                incomingReturnsData.map((item) =>
                  item._id === id ? { ...item, [field]: value } : item
                )
              );
              break;
            default:
              throw new Error(`Unknown source: ${source}`);
          }
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [incomingBookingsData, incomingReturnsData]
  );

  const markAsReturned = async (id) => {
    // Fetch the return data to check current fields
    const returnItem = incomingReturnsData.find((item) => item._id === id);

    try {
      // Ensure `returnShippingMethod` and `returnDate` are set if not already set
      if (!returnItem.returnShippingMethod) {
        await updateField(id, "returnShippingMethod", "nydalen", "returns");
      }
      if (!returnItem.returnDate) {
        await updateField(
          id,
          "returnDate",
          new Date().toISOString(),
          "returns"
        );
      }

      // Update the return status to "returned"
      await updateField(id, "returnStatus", "returned", "returns");

      // **Set the markedAsReturnedDate to now**
      await updateField(
        id,
        "markedAsReturnedDate",
        new Date().toISOString(),
        "returns"
      );
    } catch (error) {
      console.error("Error updating return information:", error);
    }
  };

  //Defined to mark a return as in stock directly
  const markAsRepaired = async (id) => {
    try {
      await updateField(id, "returnStatus", "repaired", "returns");
    } catch (error) {
      console.error("Error marking as repaired:", error);
    }
  };

  const updateInventoryOperationalStatus = useCallback(
    async (serialNumber, newStatus) => {
      try {
        await axios.put(`/api/inventory/${serialNumber}/operationalStatus`, {
          operationalStatus: newStatus,
        });
      } catch (error) {
        console.error("Error updating inventory operational status:", error);
      }
    },
    []
  );

  const incomingBookingsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "deliveryTypeFromCustomer",
      },
      {
        Header: "Booking Date",
        accessor: "bookingDate",
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString("nb-NO").replace(/\//g, "."),
      },
      { Header: "Booking Time", accessor: "bookingTime" },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = async () => {
            const {
              _id,
              serialNumber,
              bookingStatus,
              deliveryTypeFromCustomer,
            } = row.original;

            if (bookingStatus === "new") {
              try {
                // Set bookingDate to today's date if deliveryTypeFromCustomer is "nydalen"
                if (deliveryTypeFromCustomer === "nydalen") {
                  await updateField(
                    _id,
                    "bookingDate",
                    new Date().toISOString(),
                    "bookings"
                  );
                }

                await updateInventoryOperationalStatus(
                  serialNumber,
                  "repair-booked"
                );
                await updateField(_id, "bookingStatus", "received", "bookings");
              } catch (error) {
                console.error("Error in updating booking status:", error);
              }
            }
          };

          let buttonText =
            row.original.bookingStatus === "new" ? "Mark as received" : "";

          return buttonText ? (
            <button
              onClick={handleActionClick}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              {buttonText}
            </button>
          ) : null;
        },
      },
    ],
    [updateField, updateInventoryOperationalStatus]
  );

  const incomingReturnsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "returnShippingMethod",
      },
      {
        Header: "Return Date",
        accessor: "returnDate",
        Cell: ({ value }) => {
          if (value === null || isNaN(new Date(value))) {
            return ""; // or "Not selected" for clarity
          }
          return new Date(value)
            .toLocaleDateString("nb-NO")
            .replace(/\//g, ".");
        },
      },
      { Header: "Return Time", accessor: "returnTime" },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          return row.original.returnStatus === "new" ? (
            <button
              onClick={() => toggleReturnModal(row.original)}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              Mark as returned
            </button>
          ) : null;
        },
      },
    ],
    [toggleReturnModal]
  );

  const compareDateToToday = (dateString) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to start of the day
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0); // Normalize the compared date

    if (date.getTime() === today.getTime()) {
      return "today";
    } else if (date < today) {
      return "past";
    } else {
      return "future";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const bookingsResponse = await axios.get("/api/bookings");
        const incomingBookings = bookingsResponse.data.filter(
          (booking) => booking.bookingStatus === "new"
        );
        setIncomingBookingsData(incomingBookings);

        const returnsResponse = await axios.get("/api/returns");
        const filteredReturns = returnsResponse.data.filter(
          (ret) => ret.returnStatus === "new"
        );
        setIncomingReturnsData(filteredReturns);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const incomingBookingsTableInstance = useTable(
    {
      columns: incomingBookingsColumns,
      data: incomingBookingsData,
      initialState: { sortBy: [{ id: "bookingDate", desc: false }] },
    },
    useSortBy
  );

  const incomingReturnsTableInstance = useTable(
    {
      columns: incomingReturnsColumns,
      data: incomingReturnsData,
      initialState: { sortBy: [{ id: "returnDate", desc: false }] },
    },
    useSortBy
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow p-4 h-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Incoming Bikes Table (New Returns) */}
        <div className="flex flex-col w-full h-full">
          <div className="flex justify-center mt-2 mb-4">
            <h2 className="text-xl font-semibold text-center flex items-center">
              Incoming Bikes - Returns
              <span className="bg-joule text-white text-sm px-3 py-1 rounded-full ml-2">
                {incomingReturnsTableInstance.rows.length} expected returns
              </span>
            </h2>
          </div>
          <div className="overflow-auto max-h-[calc(100vh-10vh)] min-h-[calc(100vh-10vh)] shadow shadow-black rounded-lg">
            <table
              {...incomingReturnsTableInstance.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-joule text-white sticky top-0 z-10">
                {incomingReturnsTableInstance.headerGroups.map(
                  (headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                            index === 0 ? "rounded-tl-lg" : ""
                          } ${
                            index === headerGroup.headers.length - 1
                              ? "rounded-tr-lg"
                              : ""
                          }`}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                )}
              </thead>
              <tbody
                {...incomingReturnsTableInstance.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {incomingReturnsTableInstance.rows.map((row) => {
                  incomingReturnsTableInstance.prepareRow(row);
                  const rowDateStatus = compareDateToToday(
                    row.original.returnDate
                  );
                  const rowClassName =
                    rowDateStatus === "today"
                      ? "bg-yellow-500 bg-opacity-50"
                      : rowDateStatus === "past"
                      ? "bg-red-500 bg-opacity-50"
                      : "";
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`hover:bg-gray-100 ${rowClassName}`}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-4 text-sm whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* Modal for Return Confirmation */}
          {returnModalData && (
            <ReturnConfirmationModal
              isOpen={!!returnModalData}
              data={returnModalData}
              source={returnModalData.source}
              toggleModalVisibility={() => toggleReturnModal()}
              markAsReturned={markAsReturned}
              markAsRepaired={markAsRepaired}
            />
          )}
        </div>

        {/* Incoming Bikes Table (New Service Bookings) */}
        <div className="flex flex-col w-full h-full">
          <div className="flex justify-center mt-2 mb-4">
            <h2 className="text-xl font-semibold text-center flex items-center">
              Incoming Bikes - Service
              <span className="bg-joule text-white text-sm px-3 py-1 rounded-full ml-2">
                {incomingBookingsTableInstance.rows.length} booked services
              </span>
            </h2>
          </div>
          <div className="overflow-auto max-h-[calc(100vh-10vh)] min-h-[calc(100vh-10vh)] shadow shadow-black rounded-lg">
            <table
              {...incomingBookingsTableInstance.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-joule text-white sticky top-0 z-10">
                {incomingBookingsTableInstance.headerGroups.map(
                  (headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                            index === 0 ? "rounded-tl-lg" : ""
                          } ${
                            index === headerGroup.headers.length - 1
                              ? "rounded-tr-lg"
                              : ""
                          }`}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                )}
              </thead>
              <tbody
                {...incomingBookingsTableInstance.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {incomingBookingsTableInstance.rows.map((row) => {
                  incomingBookingsTableInstance.prepareRow(row);
                  const rowDateStatus = compareDateToToday(
                    row.original.bookingDate
                  );
                  const rowClassName =
                    rowDateStatus === "today"
                      ? "bg-yellow-500 bg-opacity-50"
                      : rowDateStatus === "past"
                      ? "bg-red-500 bg-opacity-50"
                      : "";
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`hover:bg-gray-100 ${rowClassName}`}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-4 text-sm whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomingBikes;
