import React, { useState, useEffect } from "react";
import axios from "axios";
import Calendar from "react-calendar";
import { format } from "date-fns";
import { pickupOrder, deliveryOrder } from "../components/TransportAPI";

const TransportBooking = () => {
  const [data, setData] = useState({
    subscriptionId: "",
    orderId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    serialNumber: "",
    address: "",
    postalCode: "",
    city: "",
    bookingDate: "",
    selectedTimeslot: "",
  });

  const [selectedType, setSelectedType] = useState("DELIVERY"); // "DELIVERY" or "PICK_UP"
  const [serialNumber, setSerialNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoadingAutofill, setIsLoadingAutofill] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  // --------------- State to charge customer for transport if true ------------
  const [chargeCustomer, setChargeCustomer] = useState(false);

  // ---------------- For transport availability / timeslots -------------------
  const [transportAvailability, setTransportAvailability] = useState({});
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  // --------------------------------------------------------------------------
  // 1) AUTOFILL: fetch subscription details from Circuly using serialNumber
  // --------------------------------------------------------------------------
  const handleAutofill = async () => {
    setIsLoadingAutofill(true);
    try {
      const itemResponse = await axios.get(`/api/circuly-data/${serialNumber}`);
      const subscriptionId = itemResponse.data.subscription_id;

      const subscriptionResponse = await axios.get(
        `/api/circuly-subscription/${subscriptionId}`
      );
      const contactInfo = subscriptionResponse.data.customer;

      setData((prev) => ({
        ...prev,
        subscriptionId,
        orderId: subscriptionResponse.data.order_id,
        firstName: contactInfo.address.shipping.first_name,
        lastName: contactInfo.address.shipping.last_name,
        email: contactInfo.email,
        phone: contactInfo.phone,
        address: contactInfo.address.shipping.street,
        postalCode: contactInfo.address.shipping.postal_code,
        city: contactInfo.address.shipping.city,
      }));

      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        error.response?.data || "An error occurred during autofill"
      );
    } finally {
      setIsLoadingAutofill(false);
    }
  };

  // --------------------------------------------------------------------------
  // 2) TYPE SELECT and SerialNumber
  // --------------------------------------------------------------------------
  const handleTypeSelect = (type) => {
    setSelectedType(type);
    // Reset timeslots if needed
    setAvailableTimeslots([]);
    setData((prev) => ({ ...prev, selectedTimeslot: "" }));
  };

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      serialNumber: serialNumber,
    }));
  }, [serialNumber]);

  // --------------------------------------------------------------------------
  // 3) SUBMIT => Transport API + Optional Payment
  // --------------------------------------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingSubmit(true);
    try {
      if (selectedType === "DELIVERY") {
        await deliveryOrder(data);
      } else {
        await pickupOrder(data);
      }
      setSuccessMessage("Opter booking successful!");
      setErrorMessage("");

      // If 'chargeCustomer' is checked, trigger one-time payment
      if (chargeCustomer) {
        const price = data?.selectedTimeslot?.price || 0;
        if (price > 0) {
          const paymentData = {
            amount: price,
            order_id: data.orderId,
            message: `Kostnad for transport den ${data.bookingDate}`,
            products: [
              {
                product: "Manuell transportbooking",
                amount: price,
                tax_percent: 25,
                quantity: 1,
              },
            ],
          };
          await axios.post("/api/circuly/one-time-payment", paymentData);
          setSuccessMessage(
            "Opter booking successful! One-time payment charged in Circuly."
          );
        }
      }
    } catch (error) {
      const errMsg =
        error.response?.data?.message ||
        error.response?.data ||
        "An unknown error occurred";
      setErrorMessage(errMsg);
      setSuccessMessage("");
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  // --------------------------------------------------------------------------
  // 4) FETCH transport availability from /api/transport/:postal
  //    whenever postalCode changes
  // --------------------------------------------------------------------------
  useEffect(() => {
    const fetchTransportData = async () => {
      if (!data.postalCode) {
        setTransportAvailability({});
        return;
      }
      try {
        const res = await axios.get(`/api/transport/${data.postalCode}`);
        setTransportAvailability(res.data);
      } catch (error) {
        console.error("Error fetching transport availability:", error);
        setTransportAvailability({});
      }
    };
    fetchTransportData();
  }, [data.postalCode]);

  // --------------------------------------------------------------------------
  // 5) handleDateChange: store the chosen date, derive timeslots if transport
  // --------------------------------------------------------------------------
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    const formattedDate = format(newDate, "yyyy-MM-dd");

    // Store in data
    setData((prev) => ({
      ...prev,
      bookingDate: formattedDate,
      selectedTimeslot: "",
    }));

    // Build timeslots if user chose DELIVERY or PICK_UP
    if (selectedType === "DELIVERY" || selectedType === "PICK_UP") {
      // If "DELIVERY", we look at transportAvailability.deliveryType.delivery
      // If "PICK_UP", we look at transportAvailability.deliveryType.pickup
      const relevant =
        selectedType === "DELIVERY"
          ? transportAvailability?.deliveryType?.delivery
          : transportAvailability?.deliveryType?.pickup;

      if (!relevant) {
        setAvailableTimeslots([]);
        return;
      }

      // Check day/evening availability by weekday
      const dayOfWeek = format(newDate, "EEEE"); // e.g., "Monday"
      const timeslots = [];
      if (relevant.day?.availability?.includes(dayOfWeek)) {
        timeslots.push({
          key: "day",
          from: "08:00",
          to: "16:00",
          price: relevant.day.price || 0,
          category: relevant.day.category || "singeltur",
        });
      }
      if (relevant.evening?.availability?.includes(dayOfWeek)) {
        timeslots.push({
          key: "evening",
          from: "16:00",
          to: "22:00",
          price: relevant.evening.price || 0,
          category: relevant.evening.category || "singeltur",
        });
      }

      // If only one timeslot => auto-select
      if (timeslots.length === 1) {
        setData((prev) => ({
          ...prev,
          selectedTimeslot: timeslots[0],
        }));
      }
      setAvailableTimeslots(timeslots);
    } else {
      setAvailableTimeslots([]);
    }
  };

  // --------------------------------------------------------------------------
  // 6) handleTimeslotSelect
  // --------------------------------------------------------------------------
  const handleTimeslotSelect = (slot) => {
    setData((prev) => ({
      ...prev,
      selectedTimeslot: slot,
    }));
  };

  // --------------------------------------------------------------------------
  // 7) tileDisabled logic:
  //    - We want earliest day to be "day after tomorrow"
  //    - Also disable the tile if no timeslots exist for that date
  // --------------------------------------------------------------------------
  const tileDisabled = ({ date, view }) => {
    if (view !== "month") return false; // Only disable in month view

    // 1) Earliest day is day after tomorrow
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
    if (date < dayAfterTomorrow) {
      return true;
    }

    // 2) If user selected "DELIVERY" or "PICK_UP", check timeslots
    if (selectedType === "DELIVERY" || selectedType === "PICK_UP") {
      // If we have no transportAvailability => disable
      if (!transportAvailability?.deliveryType) {
        return true;
      }
      const relevant =
        selectedType === "DELIVERY"
          ? transportAvailability.deliveryType.delivery
          : transportAvailability.deliveryType.pickup;

      // If still no relevant => disable
      if (!relevant) {
        return true;
      }

      // Check day/evening arrays for this dayOfWeek
      const dayOfWeek = format(date, "EEEE");
      const dayAvailable = relevant.day?.availability?.includes(dayOfWeek);
      const eveningAvailable =
        relevant.evening?.availability?.includes(dayOfWeek);

      // If neither day nor evening => disable
      if (!dayAvailable && !eveningAvailable) {
        return true;
      }
    }

    // If not transport or if there's timeslots => it's enabled
    return false;
  };

  // --------------------------------------------------------------------------
  // RENDER
  // --------------------------------------------------------------------------
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full h-full mx-auto p-12 rounded-lg bg-white max-h-[calc(100vh-10vh)] min-h-[calc(100vh-10vh)]"
    >
      <h1 className="text-3xl font-bold mb-8 text-center text-black">
        Opter Booking
      </h1>

      <div className="flex flex-wrap md:flex-nowrap mb-6 space-y-6 md:space-y-0 md:space-x-6">
        {/* LEFT SIDE: Inputs + Calendar */}
        <div className="w-full md:w-1/2 p-4 border shadow-lg rounded-lg space-y-4 max-h-[calc(100vh-20vh)] min-h-[calc(100vh-20vh)] overflow-auto">
          {/* 1) SERIAL + AUTOFILL */}
          <div className="flex space-x-4 items-center">
            <div className="flex flex-col flex-grow space-y-2">
              <h2 className="text-lg font-semibold text-black">
                Input Serial Number
              </h2>
              <div className="flex items-center relative">
                <input
                  type="text"
                  placeholder="Enter Serial Number"
                  className="flex-grow p-3 border rounded-l-lg"
                  value={serialNumber}
                  onChange={(e) => setSerialNumber(e.target.value)}
                />
                <button
                  type="button"
                  className="bg-joule text-white px-6 rounded-r-lg hover:bg-gray-600 ml-2"
                  style={{ height: "46px" }}
                  onClick={handleAutofill}
                  disabled={isLoadingAutofill}
                >
                  {isLoadingAutofill ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                  ) : (
                    "Autofill"
                  )}
                </button>
              </div>
            </div>

            {/* 2) POSTAL CODE */}
            <div className="flex flex-col flex-grow space-y-2">
              <h2 className="text-lg font-semibold text-black">
                Input Postal Code
              </h2>
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Enter Postal Code"
                  className="flex-grow p-3 border rounded-lg"
                  value={data.postalCode}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, postalCode: e.target.value }))
                  }
                />
              </div>
            </div>
          </div>

          {/* 3) ORDER TYPE */}
          <div className="flex flex-col space-y-2 relative">
            <h2 className="text-lg font-semibold text-black">Order Type</h2>
            <div className="flex justify-around">
              <button
                type="button"
                onClick={() => handleTypeSelect("DELIVERY")}
                className={`w-1/2 px-4 py-2 rounded-lg text-lg transition-colors duration-200 ${
                  selectedType === "DELIVERY"
                    ? "bg-joule text-white shadow-md focus:scale-105"
                    : "bg-gray-100 text-jouletext2"
                } hover:bg-joule hover:text-white`}
              >
                DELIVERY
              </button>
              <button
                type="button"
                onClick={() => handleTypeSelect("PICK_UP")}
                className={`w-1/2 px-4 py-2 rounded-lg text-lg transition-colors duration-200 ${
                  selectedType === "PICK_UP"
                    ? "bg-joule text-white shadow-md focus:scale-105"
                    : "bg-gray-100 text-jouletext2"
                } hover:bg-joule hover:text-white`}
              >
                PICK UP
              </button>
            </div>
          </div>

          {/* 4) INLINE CALENDAR FOR DATE SELECTION */}
          <div className="bg-white p-6 rounded-lg relative flex flex-col items-center justify-center">
            <h2 className="text-center text-lg font-semibold text-black mb-4">
              Select a Date
            </h2>
            <div className="flex justify-center border border-gray-300 rounded-lg shadow-sm p-4">
              <Calendar
                onChange={handleDateChange}
                value={selectedDate}
                selectRange={false}
                next2Label={null}
                prev2Label={null}
                className="w-full h-full"
                locale="nb"
                tileDisabled={tileDisabled}
              />
            </div>

            {/* 5) TIMESLOTS if date + transport (DELIVERY/PICK_UP) */}
            {selectedDate && availableTimeslots.length > 0 && (
              <div className="mt-4">
                <h2 className="text-center text-lg font-semibold text-black mb-4">
                  Available Timeslots
                </h2>
                <div className="flex flex-wrap justify-center gap-3">
                  {availableTimeslots.map((slot) => {
                    const isSelected = data.selectedTimeslot?.key === slot.key;
                    return (
                      <button
                        key={slot.key}
                        type="button"
                        onClick={() => handleTimeslotSelect(slot)}
                        className={`w-auto px-4 py-2 rounded-lg text-lg transition-colors duration-200 ${
                          isSelected
                            ? "bg-joule text-white shadow-md focus:scale-105"
                            : "bg-gray-100 text-jouletext2"
                        } hover:bg-joule hover:text-white`}
                      >
                        {slot.from} - {slot.to} ({slot.category}) NOK{" "}
                        {slot.price}
                        {",-"}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* RIGHT SIDE: BOOKING DATA */}
        <div className="w-full md:w-1/2 p-4 border shadow-lg rounded-lg max-h-[calc(100vh-20vh)] min-h-[calc(100vh-20vh)] overflow-auto">
          <h2 className="text-lg font-semibold text-black mb-4">
            Booking Data
          </h2>
          <div className="flex flex-col space-y-4">
            {/* FIRST NAME */}
            <FormRow
              label="First Name:"
              value={data.firstName}
              onChange={(val) =>
                setData((prev) => ({ ...prev, firstName: val }))
              }
            />

            {/* LAST NAME */}
            <FormRow
              label="Last Name:"
              value={data.lastName}
              onChange={(val) =>
                setData((prev) => ({ ...prev, lastName: val }))
              }
            />

            {/* STREET ADDRESS */}
            <FormRow
              label="Street:"
              value={data.address}
              onChange={(val) => setData((prev) => ({ ...prev, address: val }))}
            />

            {/* CITY */}
            <FormRow
              label="City:"
              value={data.city}
              onChange={(val) => setData((prev) => ({ ...prev, city: val }))}
            />

            {/* POSTAL CODE (READ-ONLY AFTER AUTOFILL?) */}
            <FormRow
              label="Postal Code:"
              value={data.postalCode}
              readOnly
              onChange={() => {}}
            />

            {/* EMAIL */}
            <FormRow
              label="Email:"
              value={data.email}
              type="email"
              onChange={(val) => setData((prev) => ({ ...prev, email: val }))}
            />

            {/* PHONE */}
            <FormRow
              label="Mobile Phone:"
              value={data.phone}
              type="tel"
              onChange={(val) => setData((prev) => ({ ...prev, phone: val }))}
            />

            {/* Charge customer checkbox */}
            <div className="flex flex-col space-y-2 py-4">
              <label className="inline-flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="h-5 w-5 accent-[#1a474e] border-2 border-gray-400 rounded-md"
                  checked={chargeCustomer}
                  onChange={(e) => setChargeCustomer(e.target.checked)}
                />
                <span className="text-black font-semibold">
                  Check button to charge customer
                </span>
              </label>
            </div>

            {/* SUBMIT */}
            <button
              type="submit"
              className={`w-full md:w-auto py-3 px-6 rounded-lg flex justify-center items-center
    ${
      isLoadingSubmit || !data.selectedTimeslot
        ? "bg-gray-300 text-gray-500 cursor-not-allowed" // Disabled state
        : "bg-joule hover:bg-gray-600 text-white cursor-pointer" // Enabled state
    }`}
              disabled={isLoadingSubmit || !data.selectedTimeslot} // Disable if selectedTimeslot is empty
            >
              {isLoadingSubmit ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                  Laster...
                </>
              ) : (
                "Submit Order"
              )}
            </button>
            {/* Error/Success message */}
            {successMessage && (
              <p className="text-green-500 text-center mt-4">
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="text-red-500 text-center mt-4">{errorMessage}</p>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default TransportBooking;

// -----------------------------------------------------------------------------
// A small re-usable form row component
// -----------------------------------------------------------------------------
const FormRow = ({
  label,
  value,
  onChange,
  type = "text",
  readOnly = false,
}) => (
  <div className="flex flex-col sm:flex-row items-center">
    <label className="sm:w-1/3 mb-2 sm:mb-0">{label}</label>
    <input
      type={type}
      readOnly={readOnly}
      className={`flex-grow p-3 border rounded-lg ${
        readOnly ? "bg-gray-300" : ""
      }`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);
