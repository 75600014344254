import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { DateTime } from "luxon";

/* 
  1) formatDate(dt, timeframe)
     (unchanged)
*/
function formatDate(dt, timeframe) {
  let rawDate;
  let label;

  switch (timeframe) {
    case "daily":
      rawDate = dt.startOf("day");
      label = rawDate.toFormat("ccc, dd LLL"); // e.g. "Mon, 20 Mar"
      break;
    case "weekly":
      rawDate = dt.startOf("week");
      label = `Week ${dt.weekNumber} (${dt.year})`;
      break;
    case "monthly":
      rawDate = dt.startOf("month");
      label = rawDate.toFormat("LLLL yyyy");
      break;
    case "yearly":
      rawDate = dt.startOf("year");
      label = rawDate.toFormat("yyyy");
      break;
    default:
      rawDate = dt;
      label = dt.toISODate();
  }

  return { rawDate, label };
}

/* 
  2) generateEmptyChartData(timeframe, sDate, eDate)
     Now each bucket has FIVE counters,
     corresponding to the two Circuly statuses + three Mongo statuses.
*/
function generateEmptyChartData(timeframe, sDate, eDate) {
  const start = DateTime.fromISO(sDate).startOf("day");
  const end = DateTime.fromISO(eDate).endOf("day");

  const incrementMapping = {
    daily: { days: 1 },
    weekly: { weeks: 1 },
    monthly: { months: 1 },
    yearly: { years: 1 },
  };

  const emptyData = [];
  let cursor = start;

  while (cursor <= end) {
    const { rawDate, label } = formatDate(cursor, timeframe);

    emptyData.push({
      rawDate,
      bucket: label,

      // Circuly statuses
      readyToActivate: 0,
      notConfirmed: 0,

      // Mongo statuses
      inClarification: 0,
      readyForCustomer: 0,
      fulfilled: 0,
    });

    cursor = cursor.plus(incrementMapping[timeframe]);
  }

  return emptyData;
}

/*
  3) groupOrdersByTime()
     - STILL builds a baseline for each date
     - Increments one of FIVE counters, depending on whether an order came from Circuly or Mongo.
*/
function groupOrdersByTime(combinedOrders, timeframe, sDate, eDate) {
  const baseline = generateEmptyChartData(timeframe, sDate, eDate);
  const baselineMap = {};

  baseline.forEach((entry, idx) => {
    baselineMap[entry.rawDate.toISO()] = idx;
  });

  const start = DateTime.fromISO(sDate).startOf("day");
  const end = DateTime.fromISO(eDate).endOf("day");

  combinedOrders.forEach((order) => {
    // We'll unify the date field name: Circuly => "tag_date", Mongo => "orderTagDate"
    // Decide which to use:
    const dateField = order.tag_date || order.orderTagDate;
    if (!dateField) return;

    const dt = DateTime.fromISO(dateField, { zone: "utc" }).toLocal();
    if (dt < start || dt > end) return;

    const { rawDate } = formatDate(dt, timeframe);
    const idx = baselineMap[rawDate.toISO()];
    if (idx === undefined) return;

    // 4) Decide which status to increment
    if (order.source === "circuly") {
      // This is a Circuly order
      const isReady =
        order.payment_status === "paid" && order.tag === "in clarification";
      if (isReady) {
        baseline[idx].readyToActivate += 1;
      } else {
        baseline[idx].notConfirmed += 1;
      }
    } else {
      // This is a Mongo order
      // "new" => "in clarification"
      // "ready for customer"
      // "fulfilled"
      switch (order.orderStatus) {
        case "new":
          baseline[idx].inClarification += 1;
          break;
        case "ready for customer":
          baseline[idx].readyForCustomer += 1;
          break;
        case "fulfilled":
          baseline[idx].fulfilled += 1;
          break;
        default:
          // If there's any other status, ignore or handle differently
          break;
      }
    }
  });

  return baseline;
}

/*
  5) Main Component: OrdersDashboard
*/
export default function OrdersDashboard() {
  const [circulyOrders, setCirculyOrders] = useState([]);
  const [mongoOrders, setMongoOrders] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [timeframe, setTimeframe] = useState("daily");
  const [startDate, setStartDate] = useState(DateTime.local().toISODate());
  const [endDate, setEndDate] = useState(
    DateTime.local().plus({ days: 14 }).toISODate()
  );

  /* 
     6) Fetch from Circuly 
        => Filter for subscription===true && subscription_active===false
        => Mark them with "source='circuly'" for easier grouping logic
  */
  useEffect(() => {
    async function fetchCirculyOrders() {
      try {
        const response = await axios.get("/api/circuly-orders/open");
        const raw = response.data.orders || [];

        const relevant = raw.filter((order) =>
          order.items.some(
            (item) =>
              item.subscription === true && item.subscription_active === false
          )
        );

        // Add "source" property
        const withSource = relevant.map((o) => ({ ...o, source: "circuly" }));
        setCirculyOrders(withSource);
      } catch (error) {
        console.error("Error fetching Circuly orders:", error);
      }
    }
    fetchCirculyOrders();
  }, []);

  /*
     7) Fetch from Mongo
        => Mark them with "source='mongo'"
  */
  useEffect(() => {
    async function fetchMongoOrders() {
      try {
        const response = await axios.get("/api/orders");
        const raw = response.data || [];

        // Add "source" property so we know these are from the new DB
        const withSource = raw.map((o) => ({ ...o, source: "mongo" }));
        setMongoOrders(withSource);
      } catch (error) {
        console.error("Error fetching Mongo orders:", error);
      }
    }
    fetchMongoOrders();
  }, []);

  /*
     8) Combine Circuly + Mongo => single array => group => build chart data
  */
  useEffect(() => {
    // Wait until both queries have completed
    if (!circulyOrders.length && !mongoOrders.length) {
      // If both are empty, just show the empty baseline
      setChartData(generateEmptyChartData(timeframe, startDate, endDate));
      return;
    }

    // Combine them
    const combined = [...circulyOrders, ...mongoOrders];
    // Now group and produce chart data
    const data = groupOrdersByTime(combined, timeframe, startDate, endDate);
    setChartData(data);
  }, [circulyOrders, mongoOrders, timeframe, startDate, endDate]);

  // 1) A custom Legend component to reverse the default order:
  function CustomLegend({ payload }) {
    // Reverse the order so "Not Confirmed" (topmost bar) appears first
    const reversed = [...payload].reverse();

    return (
      <div style={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
        {reversed.map((entry) => (
          <div
            key={entry.value}
            style={{ color: entry.color, fontWeight: 500 }}
          >
            <span style={{ marginRight: 6 }}>■</span>
            {entry.value}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen px-4 pt-8 pb-11">
      <div className="bg-white shadow-sm border rounded-lg p-6 space-y-10 h-full">
        <h1 className="text-2xl font-bold mb-2 text-gray-800">
          Orders Dashboard
        </h1>

        {/* Controls */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div className="flex items-center space-x-2">
            <label htmlFor="timeframe" className="font-semibold text-gray-700">
              Group by:
            </label>
            <select
              id="timeframe"
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <div className="flex flex-col">
              <label
                htmlFor="startDate"
                className="text-sm font-semibold text-gray-700 mb-1"
              >
                Start Date
              </label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="endDate"
                className="text-sm font-semibold text-gray-700 mb-1"
              >
                End Date
              </label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Chart */}
        <div className="w-full h-[1000px] my-4">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
              <XAxis
                dataKey="bucket"
                stroke="#4B5563"
                interval={0}
                angle={-45}
                textAnchor="end"
                tickMargin={10}
              />
              <YAxis stroke="#4B5563" />
              <Tooltip />

              {/* 3) Use our CustomLegend for reversed top-to-bottom order */}
              <Legend
                content={<CustomLegend />}
                layout="horizontal"
                verticalAlign="top"
                align="center"
                // Possibly add a little margin so it’s not right on top of the chart
                wrapperStyle={{ paddingBottom: "40px" }}
              />

              {/*
              4) Declare bars from bottom to top in code:
                 1) Fulfilled   (#1A474E)
                 2) Ready for Customer (#A5A58D)
                 3) In Clarification  (#C1E4EA)
                 4) Ready To Activate (#FFDEC5)
                 5) Not Confirmed     (pick a color)
               The last <Bar> is the "top" of the stack.
            */}

              {/* Bottom-most */}
              <Bar
                dataKey="fulfilled"
                name="Fulfilled"
                fill="#1A474E"
                stackId="orders"
              >
                <LabelList
                  dataKey={(d) =>
                    d.fulfilled +
                    d.readyForCustomer +
                    d.inClarification +
                    d.readyToActivate +
                    d.notConfirmed
                  }
                  position="top"
                />
              </Bar>

              <Bar
                dataKey="readyForCustomer"
                name="Ready For Customer"
                fill="#A5A58D"
                stackId="orders"
              >
                <LabelList
                  dataKey={(d) =>
                    d.fulfilled +
                    d.readyForCustomer +
                    d.inClarification +
                    d.readyToActivate +
                    d.notConfirmed
                  }
                  position="top"
                />
              </Bar>

              <Bar
                dataKey="inClarification"
                name="In Clarification"
                fill="#C1E4EA"
                stackId="orders"
              >
                <LabelList
                  dataKey={(d) =>
                    d.fulfilled +
                    d.readyForCustomer +
                    d.inClarification +
                    d.readyToActivate +
                    d.notConfirmed
                  }
                  position="top"
                />
              </Bar>

              <Bar
                dataKey="readyToActivate"
                name="Ready To Activate"
                fill="#FFDEC5"
                stackId="orders"
              >
                <LabelList
                  dataKey={(d) =>
                    d.fulfilled +
                    d.readyForCustomer +
                    d.inClarification +
                    d.readyToActivate +
                    d.notConfirmed
                  }
                  position="top"
                />
              </Bar>

              {/* Top-most */}
              <Bar
                dataKey="notConfirmed"
                name="Not Confirmed"
                fill="#FCA5A5" // or any color that suits "Not Confirmed"
                stackId="orders"
              >
                <LabelList
                  dataKey={(d) =>
                    d.fulfilled +
                    d.readyForCustomer +
                    d.inClarification +
                    d.readyToActivate +
                    d.notConfirmed
                  }
                  position="top"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
