// ReturnDashboard.jsx

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { DateTime } from "luxon";

/* 1) formatDate(dt, timeframe)
   - Identical to your existing approach, grouping daily/weekly/monthly/yearly.
*/
function formatDate(dt, timeframe) {
  let rawDate;
  let label;

  switch (timeframe) {
    case "daily":
      rawDate = dt.startOf("day");
      label = rawDate.toFormat("ccc, dd LLL"); // e.g. "Mon, 20 Mar"
      break;
    case "weekly":
      rawDate = dt.startOf("week");
      label = `Week ${dt.weekNumber} (${dt.year})`;
      break;
    case "monthly":
      rawDate = dt.startOf("month");
      label = rawDate.toFormat("LLLL yyyy");
      break;
    case "yearly":
      rawDate = dt.startOf("year");
      label = rawDate.toFormat("yyyy");
      break;
    default:
      rawDate = dt;
      label = dt.toISODate();
  }
  return { rawDate, label };
}

/* 2) generateEmptyChartData(timeframe, sDate, eDate)
   - For the Return Dashboard, we have 3 possible statuses:
     "new", "returned", "repaired".
   - We'll create a baseline array of date buckets, each with counters=0 for these 3 statuses.
*/
function generateEmptyChartData(timeframe, sDate, eDate) {
  const start = DateTime.fromISO(sDate).startOf("day");
  const end = DateTime.fromISO(eDate).endOf("day");

  const incrementMap = {
    daily: { days: 1 },
    weekly: { weeks: 1 },
    monthly: { months: 1 },
    yearly: { years: 1 },
  };

  const statuses = ["new", "returned", "repaired"];
  const baselineData = [];

  let cursor = start;
  while (cursor <= end) {
    const { rawDate, label } = formatDate(cursor, timeframe);

    // Build an object with { new:0, returned:0, repaired:0 }
    const counters = {};
    statuses.forEach((st) => {
      counters[st] = 0;
    });

    baselineData.push({
      rawDate,
      bucket: label,
      ...counters,
    });

    cursor = cursor.plus(incrementMap[timeframe]);
  }
  return baselineData;
}

/* 3) groupReturnsByTime(returns, timeframe, dateField, sDate, eDate)
   - We select either "cancellationDate" or "returnDate" from each object.
   - We increment the correct status in the corresponding date bucket.
*/
function groupReturnsByTime(returns, timeframe, dateField, sDate, eDate) {
  // Build a baseline
  const baseline = generateEmptyChartData(timeframe, sDate, eDate);
  const baselineMap = {};

  baseline.forEach((entry, idx) => {
    baselineMap[entry.rawDate.toISO()] = idx;
  });

  const start = DateTime.fromISO(sDate).startOf("day");
  const end = DateTime.fromISO(eDate).endOf("day");

  returns.forEach((ret) => {
    // Pick the chosen date field
    const rawIsoDate = ret[dateField];
    if (!rawIsoDate) return; // if null or undefined, skip

    const dt = DateTime.fromISO(rawIsoDate, { zone: "utc" }).toLocal();
    if (!dt.isValid) return;
    if (dt < start || dt > end) return;

    const { rawDate } = formatDate(dt, timeframe);
    const idx = baselineMap[rawDate.toISO()];
    if (idx === undefined) return;

    // Return status => "new", "returned", or "repaired"
    switch (ret.returnStatus) {
      case "new":
        baseline[idx].new += 1;
        break;
      case "returned":
        baseline[idx].returned += 1;
        break;
      case "repaired":
        baseline[idx].repaired += 1;
        break;
      default:
        // If it's missing or unrecognized, skip or handle differently
        break;
    }
  });

  return baseline;
}

/*
  4) ReturnDashboard
     - Fetch returns from "/api/returns".
     - Let user pick timeframe (daily/weekly/monthly/yearly).
     - Let user pick which date field to use (cancellationDate vs. returnDate).
     - The initial date range is [today - 2 weeks, today + 2 weeks],
       and the initial date field is "cancellationDate".
*/
export default function ReturnDashboard() {
  const [returnsData, setReturnsData] = useState([]);
  const [chartData, setChartData] = useState([]);

  // The user can pick which date field to use
  const [dateField, setDateField] = useState("cancellationDate"); // or "returnDate"
  const [timeframe, setTimeframe] = useState("daily");

  // 2 weeks before -> 2 weeks after
  const [startDate, setStartDate] = useState(
    DateTime.local().minus({ weeks: 2 }).toISODate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local().plus({ weeks: 2 }).toISODate()
  );

  // 4.1) Fetch returns once
  useEffect(() => {
    async function fetchAllReturns() {
      try {
        const response = await axios.get("/api/returns");
        if (Array.isArray(response.data)) {
          setReturnsData(response.data);
        } else {
          setReturnsData([]);
        }
      } catch (error) {
        console.error("Error fetching returns:", error);
      }
    }
    fetchAllReturns();
  }, []);

  // 4.2) Build chart data whenever returns or filters change
  useEffect(() => {
    if (!returnsData.length) {
      setChartData(generateEmptyChartData(timeframe, startDate, endDate));
      return;
    }

    const grouped = groupReturnsByTime(
      returnsData,
      timeframe,
      dateField,
      startDate,
      endDate
    );
    setChartData(grouped);
  }, [returnsData, timeframe, dateField, startDate, endDate]);

  return (
    <div className="w-full min-h-screen px-4 pt-8 pb-11">
      <div className="bg-white shadow-sm border rounded-lg p-6 space-y-6 h-full">
        <h1 className="text-2xl font-bold mb-2 text-gray-800">
          Return Dashboard
        </h1>

        {/* Controls */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          {/* Date Field Selector */}
          <div className="flex items-center space-x-2">
            <label htmlFor="dateField" className="font-semibold text-gray-700">
              Date Field:
            </label>
            <select
              id="dateField"
              value={dateField}
              onChange={(e) => setDateField(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="cancellationDate">Cancellation Date</option>
              <option value="returnDate">Return Date</option>
            </select>
          </div>

          {/* Timeframe */}
          <div className="flex items-center space-x-2">
            <label htmlFor="timeframe" className="font-semibold text-gray-700">
              Timeframe:
            </label>
            <select
              id="timeframe"
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          {/* Date Range */}
          <div className="flex items-center space-x-4">
            <div className="flex flex-col">
              <label
                htmlFor="startDate"
                className="text-sm font-semibold text-gray-700 mb-1"
              >
                Start Date
              </label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="endDate"
                className="text-sm font-semibold text-gray-700 mb-1"
              >
                End Date
              </label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Chart */}
        <div className="w-full h-[1000px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{ top: 60, right: 20, left: 20, bottom: 80 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
              <XAxis
                dataKey="bucket"
                stroke="#4B5563"
                interval={0}
                angle={-45}
                textAnchor="end"
                tickMargin={10}
              />
              <YAxis stroke="#4B5563" />
              <Tooltip />

              {/* Legend: normal (not reversed) since we have only 3 statuses */}
              <Legend
                verticalAlign="top"
                align="center"
                wrapperStyle={{ paddingBottom: "40px" }}
              />

              {/* Stacked bars for each returnStatus */}
              <Bar dataKey="new" name="New" fill="#93C5FD" stackId="returns" />
              <Bar
                dataKey="returned"
                name="Returned"
                fill="#A7F3D0"
                stackId="returns"
              />
              <Bar
                dataKey="repaired"
                name="Repaired"
                fill="#FCA5A5"
                stackId="returns"
              />

              {/* Invisible bar for total sum label */}
              <Bar dataKey="__invisible__" fill="transparent" stackId="returns">
                <LabelList
                  dataKey={(d) =>
                    // sum the statuses
                    (d.new || 0) + (d.returned || 0) + (d.repaired || 0)
                  }
                  position="top"
                  formatter={(val) => (val > 0 ? val : "")}
                  style={{ fontWeight: "bold" }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
