import React, { useState } from "react";
import Calendar from "react-calendar";
import "../calendar.css"; // Your global calendar CSS
import { format, parseISO } from "date-fns";
import nbLocale from "date-fns/locale/nb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ReactModal from "react-modal";

// Ensure modal accessibility
ReactModal.setAppElement("#root");

// Modal styling: minimal changes; same functionality, just tidied up
const modalStyles = {
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "700px",
    margin: "auto",
    padding: "20px",
    borderRadius: "16px",
    border: "3px solid #374151",
    boxShadow: "0 16px 32px rgba(0, 0, 0, 0.5)",
    overflow: "hidden", // prevents scroll
    backgroundColor: "#f7fafc",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.65)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
};

/**
 *  DateCell
 *  --------
 *  Used primarily in NewOrders.jsx to show a clickable date that opens a modal.
 *  Functionality is unchanged. We only tidied up the styling.
 */
function DateCell({ date, updateField, id, fieldToUpdate }) {
  const [showCalendar, setShowCalendar] = useState(false);

  const parsedDate = date ? parseISO(date) : null;
  const isValidDate =
    parsedDate && !isNaN(parsedDate) && parsedDate instanceof Date;

  const toggleModalVisibility = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateChange = (newDate) => {
    const formattedDate = format(newDate, "yyyy-MM-dd");
    updateField(id, fieldToUpdate, formattedDate);
    setShowCalendar(false);
  };

  return (
    <div onClick={toggleModalVisibility} className="cursor-pointer">
      {/* The modal for calendar selection */}
      <ReactModal
        isOpen={showCalendar}
        onRequestClose={toggleModalVisibility}
        contentLabel="Calendar Modal"
        style={modalStyles}
        shouldCloseOnOverlayClick={true}
      >
        <CalendarComponent
          data={{ date: parsedDate }}
          setData={handleDateChange}
        />
      </ReactModal>

      {/* Display the chosen date or "Set Date" */}
      <div className="text-sm font-medium text-gray-700">
        {isValidDate ? (
          <>
            <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            {format(parsedDate, "PP", { locale: nbLocale })}
          </>
        ) : (
          "Set Date"
        )}
      </div>
    </div>
  );
}

/**
 *  CalendarComponent
 *  -----------------
 *  The actual calendar. Used inside the modal (DateCell) or can be used inline
 */
function CalendarComponent({ data, setData }) {
  const handleDateChange = (selectedDate) => {
    setData(selectedDate);
  };

  // Prevent the modal from closing if user clicks inside the container
  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  // Function to apply styles to selected tile
  const tileClassName = ({ date }) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    if (data.date && format(data.date, "yyyy-MM-dd") === formattedDate) {
      return "bg-joule text-white font-bold rounded-lg"; // Apply your desired highlight color
    }
    return "";
  };

  return (
    <div
      onClick={handleContainerClick}
      className="bg-white p-6 rounded-lg shadow-lg w-auto max-w-lg mx-auto text-center"
    >
      <h2 className="text-2xl font-bold mb-4 text-gray-800">📅 Velg dato</h2>

      {/* The calendar wrapper */}
      <div className="border border-gray-300 rounded-lg p-3 bg-gray-50 flex justify-center">
        <Calendar
          className="react-calendar text-gray-800"
          onChange={handleDateChange}
          value={data.date || new Date()}
          selectRange={false}
          minDetail="month"
          next2Label={null}
          prev2Label={null}
          locale={nbLocale}
          tileClassName={tileClassName}
        />
      </div>

      {/* Display the currently selected date (if any) */}
      {data.date && (
        <p className="mt-4 text-lg font-medium text-gray-700">
          ✅ Valgt dato:{" "}
          <span className="text-jouledark font-bold">
            {format(data.date, "PP", { locale: nbLocale })}
          </span>
        </p>
      )}
    </div>
  );
}

// Export both components so other pages can use them.
export { DateCell, CalendarComponent };
