import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import Table from "../components/tableComponent";
import { DateTime } from "luxon";
import CreateSubscriptionModal from "../components/createSubsriptionModal";
import CreateOrderNoteModal from "../components/createOrderNoteModal";
import { format } from "date-fns";
import nbLocale from "date-fns/locale/nb";

const OrderPickingList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  //Filter related states
  const [filterInput, setFilterInput] = useState("");
  const [filterCategory, setFilterCategory] = useState("");

  //State for showing the create-subscription modal
  const [createSubscriptionData, setCreateSubscriptionData] = useState(null);

  // New: State for showing/hiding the Create Note modal
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);

  // For displaying a success/error message (inline or otherwise)
  const [notification, setNotification] = useState({ message: "", type: "" });

  // Removes a row from the table by itemId when subscription successfully created
  const handleRemoveRow = (itemId) => {
    setData((prevData) => prevData.filter((row) => row.itemId !== itemId));
  };

  // Show a notification message; type can be "success" or "error"
  const showNotification = (message, type = "success") => {
    setNotification({ message, type });

    // Clear it after 3 seconds, or do nothing if you want a permanent message
    setTimeout(() => {
      setNotification({ message: "", type: "" });
    }, 3000);
  };

  // toggle function to open/close subscription modal
  const toggleCreateSubscriptionModal = (data = null) => {
    if (data) {
      setCreateSubscriptionData(data);
    } else {
      setCreateSubscriptionData(null);
    }
  };

  // toggle function to open/close the create note modal
  const toggleCreateNoteModal = () => {
    setCreateNoteModalOpen((prev) => !prev);
  };

  function getDeliveryDateBackgroundClass(tagDate, shippingMethod) {
    if (!tagDate) return "";

    const now = new Date();
    const diffInDays = (tagDate - now) / (1000 * 60 * 60 * 24);
    console.log(
      `Checking tagDate: ${tagDate}, diffInDays: ${diffInDays}, shippingMethod: ${shippingMethod}`
    );

    if (diffInDays <= 1) {
      console.log("Applying bg-red-200");
      return "bg-red-200";
    }

    if (diffInDays <= 2 && shippingMethod === "Transport") {
      console.log("Applying bg-red-200 (Transport condition)");
      return "bg-red-200";
    }

    if (diffInDays <= 5) {
      console.log("Applying bg-yellow-200");
      return "bg-yellow-200";
    }

    console.log("No color applied.");
    return "";
  }

  //Fetch the orders
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get("/api/circuly-orders/filtered");
        console.log("API Response:", response.data);

        const fetchedItems = response.data.orders.flatMap((order) =>
          // 1) Filter out only the items where subscription === false and subscription_active === false
          order.items
            .filter(
              (item) =>
                item.subscription === true && item.subscription_active === false
            )
            // 2) Then map over only those filtered items
            .map((item) => {
              try {
                // Parse raw tag date as-is, without adjusting for time zones
                const tagDate = DateTime.fromISO(order.tag_date, {
                  setZone: true,
                });
                const hours = tagDate.hour;

                // Determine origin
                const origin =
                  order.origin === "cms"
                    ? "swap"
                    : order.origin === "checkout"
                    ? "new order"
                    : order.origin;

                // Determine shipping method based on shipping amount
                let shippingMethod =
                  order.details.shipping_amount === 0 ? "Nydalen" : "Transport";

                // If origin is swap => shippingMethod = "See Note"
                if (origin === "swap") {
                  shippingMethod = "See Note";
                }

                // Updated Delivery Time Logic
                let deliveryTime = "N/A"; // Default if no match

                if (shippingMethod === "Nydalen") {
                  if (hours >= 8 && hours <= 11) {
                    deliveryTime = "10-14";
                  } else if (hours >= 12 && hours <= 15) {
                    deliveryTime = "14-17";
                  }
                } else if (shippingMethod === "Transport") {
                  if (hours >= 8 && hours <= 11) {
                    deliveryTime = "10-17";
                  } else if (hours >= 15 && hours <= 18) {
                    deliveryTime = "16-22";
                  }
                }

                return {
                  orderId: item.order_id,
                  itemId: item.id,
                  productId: item.product_id,
                  sku: item.sku,
                  origin,
                  shippingMethod,
                  deliveryTime,
                  tagDate: tagDate.toJSDate(),
                  name: `${order.customer.address.billing.first_name} ${order.customer.address.billing.last_name}`,
                };
              } catch (error) {
                console.error("Error processing item:", item, error);
                return null;
              }
            })
        );

        const validItems = fetchedItems.filter(Boolean);
        console.log("Processed Data:", validItems);

        // 3) Build an array of unique orderIds
        const uniqueOrderIds = [...new Set(validItems.map((v) => v.orderId))];

        if (uniqueOrderIds.length > 0) {
          // 4) Fetch notes for these orderIds
          const notesResponse = await axios.post("/api/orderNotes/bulk", {
            orderIds: uniqueOrderIds,
          });

          const notesData = notesResponse.data; // array of { _id, orderId, note }
          const noteMap = new Map();
          notesData.forEach((noteObj) => {
            noteMap.set(noteObj.orderId, noteObj.note);
          });

          // 5) Merge notes into validItems
          const mergedData = validItems.map((item) => ({
            ...item,
            note: noteMap.get(item.orderId) || "", // fallback empty string if no note
          }));

          setData(mergedData);
        } else {
          // if no items, just set data
          setData(validItems);
        }
      } catch (error) {
        console.error("Error fetching items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  const columns = useMemo(
    () => [
      { Header: "Order ID", accessor: "orderId" },
      { Header: "Name", accessor: "name" },
      { Header: "SKU", accessor: "sku" },
      { Header: "Origin", accessor: "origin" },
      {
        Header: "Shipping Method",
        accessor: "shippingMethod", // Add this column to display "Nydalen" or "Transport"
      },

      {
        Header: "Delivery Date",
        accessor: "tagDate", // Keep using raw date for sorting
        sortType: (rowA, rowB) => {
          const dateA = new Date(rowA.values.tagDate).getTime();
          const dateB = new Date(rowB.values.tagDate).getTime();
          return dateA - dateB; // ascending order
        },
        Cell: ({ value }) => {
          if (!value) {
            console.error("Invalid tagDate value:", value);
            return "Invalid Date";
          }

          // Format using date-fns in Norwegian locale
          return format(value, "d. MMMM yyyy", { locale: nbLocale });
          // e.g. "20. februar 2025"
        },
      },

      {
        Header: "Delivery Time",
        accessor: "deliveryTime",
      },
      {
        Header: "Note",
        accessor: "note",
        Cell: ({ row }) => {
          const [localNote, setLocalNote] = useState(row.original.note || "");
          const originalNote = row.original.note || "";

          const handleBlur = async () => {
            if (localNote !== originalNote) {
              try {
                const orderId = row.original.orderId;
                const res = await axios.put(`/api/orderNotes/${orderId}`, {
                  note: localNote,
                });
                if (res.status === 200 && res.data) {
                  setData((prevData) =>
                    prevData.map((item) =>
                      item.orderId === orderId
                        ? { ...item, note: res.data.note }
                        : item
                    )
                  );
                }
              } catch (error) {
                console.error("Error upserting note:", error);
              }
            }
          };

          return (
            <div
              className="relative group w-96" // Force a wider input field
            >
              <input
                type="text"
                className="border p-1 rounded text-sm w-full truncate"
                value={localNote}
                onChange={(e) => setLocalNote(e.target.value)}
                onBlur={handleBlur}
                placeholder="Add note"
              />
              {localNote && (
                <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 z-10 w-64 break-words shadow-lg">
                  {localNote}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => {
          const handleActionClick = () => {
            // Pass row data into your modal state
            toggleCreateSubscriptionModal(row.original);
          };

          return (
            <button
              onClick={handleActionClick}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline mr-2 w-32"
            >
              Create Subscription
            </button>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setFilter,
    rows,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: [{ id: "tagDate", desc: false }],
      },
    },
    // <-- second parameter is a function that modifies the table hooks
    (hooks) => {
      hooks.getRowProps.push((props, { row }) => {
        const { tagDate, shippingMethod } = row.original;
        const bgClass = getDeliveryDateBackgroundClass(tagDate, shippingMethod);

        return {
          ...props,
          className: `${props.className ?? ""} ${bgClass}`.trim(),
        };
      });
    },
    // <-- subsequent parameters for your existing plugins:
    useFilters,
    useSortBy,
    usePagination
  );

  const handleFilterChange = (e) => {
    setFilterInput(e.target.value);
    if (filterCategory) {
      setFilter(filterCategory, e.target.value);
    }
  };

  // Callback for when the note is created successfully
  // to update our table data if that orderId is present.
  const handleNoteCreated = (newNote) => {
    // newNote is the object returned from server => { _id, orderId, note }
    const { orderId, note } = newNote;
    // Update local state if the orderId is in our data array
    setData((prevData) =>
      prevData.map((row) => (row.orderId === orderId ? { ...row, note } : row))
    );
    showNotification("Note created successfully!", "success");
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex-grow p-4">
      <h2 className="text-2xl font-semibold mb-4">Order Picking List</h2>

      {/* Display a notification if set */}
      {notification.message && (
        <div
          className={
            notification.type === "success"
              ? "bg-green-100 text-green-600 p-2 mb-4 rounded"
              : "bg-red-100 text-red-600 p-2 mb-4 rounded"
          }
        >
          {notification.message}
        </div>
      )}

      {/* Filter and Create Note Button Row */}
      <div className="flex justify-between items-center mb-12">
        {/* Left: Filter Inputs */}
        <div className="flex items-center space-x-2">
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="border p-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            <option value="">Select Filter Category...</option>
            {columns.map((column, idx) => (
              <option value={column.accessor} key={idx}>
                {column.Header}
              </option>
            ))}
          </select>
          <input
            value={filterInput}
            onChange={handleFilterChange}
            placeholder="Enter filter value..."
            className="border p-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>

        {/* Right: Create Note Button */}
        <button
          onClick={toggleCreateNoteModal}
          className="bg-joule text-white font-bold py-2 px-5 rounded-lg shadow-lg transition-transform transform hover:-translate-y-0.5 hover:bg-gray-800 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-joule focus:ring-offset-2"
        >
          <span className="inline-flex items-center">
            <svg
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 2a1 1 0 011 1v6h6a1 1 0 110 2h-6v6a1 1 0 11-2 0v-6H3a1 1 0 110-2h6V3a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            Create Note
          </span>
        </button>
      </div>

      {/* Table Component */}
      <Table
        columns={columns}
        data={page}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalFilteredRows={rows.length}
      />

      {createSubscriptionData && (
        <CreateSubscriptionModal
          isOpen={!!createSubscriptionData}
          data={createSubscriptionData}
          toggleModalVisibility={() => toggleCreateSubscriptionModal()}
          // onSuccess: remove row & show success
          // onError: show error
          onSuccess={(itemId) => {
            handleRemoveRow(itemId);
            showNotification("Subscription created successfully!", "success");
          }}
          onError={(errorMsg) => {
            showNotification(errorMsg, "error");
          }}
        />
      )}

      {/* CreateOrderNoteModal */}
      {createNoteModalOpen && (
        <CreateOrderNoteModal
          isOpen={createNoteModalOpen}
          toggleModalVisibility={toggleCreateNoteModal}
          onSuccess={handleNoteCreated}
          onError={(errMsg) => showNotification(errMsg, "error")}
        />
      )}
    </div>
  );
};

export default OrderPickingList;
