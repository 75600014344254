import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import ServiceCell from "../components/serviceRegistration";
import { FaSun } from "react-icons/fa";

const TodoList = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [bookingsData, setBookingsData] = useState([]);
  const [returnsData, setReturnsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState(null);

  const toggleModal = useCallback((data = null, source = null) => {
    if (data) {
      setModalData({ ...data, source: source });
    } else {
      setModalData(null);
    }
  }, []);

  // Utility function to add working days, ignoring time zones
  const addWorkingDays = (date, days) => {
    const result = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    ); // Start at midnight UTC
    let count = 0;

    while (count < days) {
      result.setUTCDate(result.getUTCDate() + 1); // Use setUTCDate to avoid local time shifts
      if (result.getUTCDay() !== 0 && result.getUTCDay() !== 6) {
        // Check if it's a weekday
        count++;
      }
    }
    return result;
  };

  // Function to calculate `dueDate` based on `type`
  const calculateDueDate = useCallback((bookingDate, type) => {
    switch (type) {
      case "Service":
        return addWorkingDays(bookingDate, 2);
      case "Reparasjon":
      case "Dekkskifte":
        return addWorkingDays(bookingDate, 2);
      default:
        return bookingDate;
    }
  }, []);

  const updateField = useCallback(
    async (id, field, value, source) => {
      try {
        let url = `/api/${source}/${id}`;
        const response = await axios.put(url, { [field]: value });
        if (response.status === 200) {
          switch (source) {
            case "orders":
              if (field === "orderStatus" && value !== "new") {
                setOrdersData(ordersData.filter((item) => item._id !== id));
              } else {
                setOrdersData(
                  ordersData.map((item) =>
                    item._id === id ? { ...item, [field]: value } : item
                  )
                );
              }
              break;
            case "bookings":
              if (
                field === "bookingStatus" &&
                !["new", "received"].includes(value)
              ) {
                setBookingsData(bookingsData.filter((item) => item._id !== id));
              } else {
                setBookingsData(
                  bookingsData.map((item) =>
                    item._id === id ? { ...item, [field]: value } : item
                  )
                );
              }
              break;
            case "returns":
              if (field === "returnStatus" && value !== "returned") {
                setReturnsData(returnsData.filter((item) => item._id !== id));
              } else {
                setReturnsData(
                  returnsData.map((item) =>
                    item._id === id ? { ...item, [field]: value } : item
                  )
                );
              }
              break;
            default:
              throw new Error(`Unknown source: ${source}`);
          }
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [ordersData, bookingsData, returnsData]
  );

  const markOrderAsRepaired = async (id) => {
    await updateField(id, "orderStatus", "ready for customer", "orders");
  };

  const markBookingAsRepaired = async (id) => {
    await updateField(id, "bookingStatus", "ready for customer", "bookings");
  };

  const markReturnAsRepaired = async (id) => {
    await updateField(id, "returnStatus", "repaired", "returns");
  };

  const ordersColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "orderShippingMethod",
      },
      {
        Header: "Delivery Date",
        accessor: "orderTagDate",
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString("nb-NO").replace(/\//g, "."),
      },
      {
        Header: "Item SKU Name",
        accessor: "itemSkuName",
      },
      {
        Header: "Note",
        accessor: "note",
        Cell: ({ row }) => {
          // Make it non-editable but show a tooltip on hover
          const localNote = row.original.note || "";

          return (
            <div className="relative group">
              {/* Non-editable input */}
              <input
                type="text"
                className="border p-1 rounded text-sm w-full truncate cursor-default"
                value={localNote}
                readOnly
                placeholder="No note"
              />

              {/* Hover box: wraps text if long, expands in height as needed */}
              {localNote && (
                <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 z-10 shadow-lg whitespace-normal break-words max-w-sm">
                  {localNote}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          return (
            <button
              onClick={() => toggleModal(row.original, "orders")}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              Start Repair
            </button>
          );
        },
      },
    ],
    [toggleModal]
  );

  // Define the columns for react-table
  const bookingsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Booking Date",
        accessor: "bookingDate",
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString("nb-NO", {
            timeZone: "Europe/Oslo",
          }),
        sortType: "datetime", // Ensure it's sorted as a date
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString("nb-NO", {
            timeZone: "Europe/Oslo",
          }),
        sortType: "datetime", // Ensure sorting works correctly
      },
      {
        Header: "Summer Tyres",
        accessor: "winterTyres",
        Cell: ({ value }) =>
          value ? (
            <div className="flex items-center justify-center bg-yellow-200 p-2 rounded">
              <FaSun
                className="text-yellow-600"
                title="Summer Tyres Required"
              />
            </div>
          ) : null,
      },
      {
        Header: "Note",
        accessor: "note",
        Cell: ({ row }) => {
          // Make it non-editable but show a tooltip on hover
          const localNote = row.original.note || "";

          return (
            <div className="relative group">
              {/* Non-editable input */}
              <input
                type="text"
                className="border p-1 rounded text-sm w-full truncate cursor-default"
                value={localNote}
                readOnly
                placeholder="No note"
              />

              {/* Hover box: wraps text if long, expands in height as needed */}
              {localNote && (
                <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 z-10 shadow-lg whitespace-normal break-words max-w-sm">
                  {localNote}
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          if (row.original.bookingStatus === "received") {
            return (
              <button
                onClick={() => toggleModal(row.original, "bookings")}
                className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
              >
                Start Repair
              </button>
            );
          }
          return null;
        },
      },
    ],
    [toggleModal]
  );

  const returnsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber", // accessor is the "key" in the data
      },
      {
        Header: "SKU",
        accessor: "itemSkuName",
      },
      {
        Header: "Actions",
        accessor: "_id", // Assuming _id is a unique identifier for each return
        Cell: ({ row }) => {
          return (
            <button
              onClick={() => toggleModal(row.original, "returns")}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              Start Repair
            </button>
          );
        },
      },
    ],
    [toggleModal]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true at the beginning of data fetching

        // Fetch Orders
        const ordersResponse = await axios.get("/api/orders");
        const newOrders = ordersResponse.data.filter(
          (order) => order.orderStatus === "new"
        );
        setOrdersData(newOrders || []);

        // Fetch Bookings
        const bookingsResponse = await axios.get("/api/bookings");
        const bookings = bookingsResponse.data.filter(
          (booking) =>
            booking.bookingStatus === "new" ||
            booking.bookingStatus === "received"
        );

        // Calculate and set `dueDate`, normalized to UTC
        const bookingsWithDueDates = bookings.map((booking) => {
          const bookingDate = new Date(booking.bookingDate);

          // Normalize bookingDate and dueDate to UTC
          const normalizedBookingDate = new Date(
            Date.UTC(
              bookingDate.getFullYear(),
              bookingDate.getMonth(),
              bookingDate.getDate()
            )
          );

          let dueDate;
          // Only do the 15-days-before logic if type = "winter_freeze" AND we have a returnDate
          if (booking.type === "winter_freeze" && booking.returnDate) {
            const rDate = new Date(booking.returnDate);

            // Normalize returnDate to midnight UTC (optional, if consistent with your logic)
            const normalizedReturnDate = new Date(
              Date.UTC(rDate.getFullYear(), rDate.getMonth(), rDate.getDate())
            );

            // Subtract 15 DAYS (calendar days)
            normalizedReturnDate.setUTCDate(
              normalizedReturnDate.getUTCDate() - 14
            );
            dueDate = normalizedReturnDate;

            // If you want 15 WORKING days, replace with:
            // dueDate = addWorkingDays(normalizedReturnDate, -15);
          } else {
            // Fall back to the normal logic for everything else
            dueDate = calculateDueDate(normalizedBookingDate, booking.type);
          }

          return {
            ...booking,
            bookingDate: normalizedBookingDate,
            dueDate,
          };
        });

        setBookingsData(bookingsWithDueDates);

        // Fetch Returns
        const returnsResponse = await axios.get("/api/returns");
        const receivedReturns = returnsResponse.data.filter(
          (ret) => ret.returnStatus === "returned"
        );
        setReturnsData(receivedReturns || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false at the end of data fetching
      }
    };
    fetchData();
  }, [calculateDueDate]);

  // Orders Table
  const ordersTableInstance = useTable(
    {
      columns: ordersColumns,
      data: ordersData,
      initialState: { sortBy: [{ id: "orderTagDate", desc: false }] },
    },
    useSortBy
  );

  // Bookings Table
  const bookingsTableInstance = useTable(
    {
      columns: bookingsColumns,
      data: bookingsData,
      initialState: {
        sortBy: [{ id: "dueDate", desc: false }],
        hiddenColumns: ["bookingDate"],
      },
      getRowId: (row) => row._id, //
    },
    useSortBy
  );

  // Returns Table
  const returnsTableInstance = useTable(
    {
      columns: returnsColumns,
      data: returnsData,
      initialState: { sortBy: [{ id: "itemSkuName", desc: false }] },
    },
    useSortBy
  );

  //Code to add background color based on the order date relative to todays date
  const getOrderDateBackgroundClass = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const oneDayBefore = new Date(new Date().setDate(today.getDate() + 1));
    const twoDaysBefore = new Date(new Date().setDate(today.getDate() + 2));

    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    oneDayBefore.setHours(0, 0, 0, 0);
    twoDaysBefore.setHours(0, 0, 0, 0);

    if (date > today && date <= twoDaysBefore) {
      return "bg-yellow-500 bg-opacity-50"; // Yellow if one or two days before orderTagDate
    } else if (date <= today) {
      return "bg-red-500 bg-opacity-50"; // Red if it is the orderTagDate or later
    }
    return ""; // No special color if more than two days before orderTagDate
  };

  // Function to handle background color logic
  const getRowBackgroundColor = (bookingDate, dueDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to avoid time discrepancies

    if (today < bookingDate) return ""; // White if before bookingDate
    if (today >= bookingDate && today <= dueDate)
      return "bg-yellow-500 bg-opacity-50"; // Yellow if between
    if (today > dueDate) return "bg-red-500 bg-opacity-50"; // Red if past dueDate
    return ""; // Default no color
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow flex flex-wrap justify-between">
      {" "}
      {/* Adjusted for spacing between tables */}
      {/* New Orders Table */}
      <div className="w-full lg:w-1/3">
        {" "}
        {/* Adjusted for spacing */}
        <div className="flex justify-center mt-2 mb-4">
          <h2 className="text-xl font-semibold text-center flex items-center">
            New Orders
            <span className="bg-joule text-white text-sm px-3 py-1 rounded-full ml-2">
              {ordersTableInstance.rows.length} orders
            </span>
          </h2>
        </div>{" "}
        {/* Uniform text size */}
        <div className="overflow-auto max-h-[calc(100vh-12vh)] min-h-[calc(100vh-12vh)] shadow shadow-black rounded-lg mx-4">
          {" "}
          {/* Dynamic table length */}
          <table
            {...ordersTableInstance.getTableProps()}
            className="min-w-full divide-y divide-gray-200"
          >
            <thead className="bg-joule text-white sticky top-0 z-10 h-12">
              {ordersTableInstance.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`px-2 py-2 text-xs text-left font-medium text-white uppercase tracking-wider ${
                        index === 0 ? "rounded-tl-lg" : ""
                      } ${
                        index === headerGroup.headers.length - 1
                          ? "rounded-tr-lg"
                          : ""
                      }`}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...ordersTableInstance.getTableBodyProps()}
              className="bg-white divide-y divide-gray-200"
            >
              {ordersTableInstance.rows.map((row) => {
                ordersTableInstance.prepareRow(row);
                const rowBgClass = getOrderDateBackgroundClass(
                  row.original.orderTagDate
                );
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`${rowBgClass} hover:bg-gray-100`}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-2 py-4 text-sm whitespace-nowrap"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Modal for New Orders */}
        {modalData && modalData.source === "orders" && (
          <ServiceCell
            isOpen={!!modalData}
            data={modalData}
            source={modalData.source}
            toggleModalVisibility={() => toggleModal(null)}
            markAsRepaired={markOrderAsRepaired}
          />
        )}
      </div>
      {/* Service Bookings Table */}
      <div className="w-full lg:w-1/3">
        {" "}
        {/* Adjusted for spacing */}
        <div className="flex justify-center mt-2 mb-4">
          <h2 className="text-xl font-semibold text-center flex items-center">
            Service Bookings
            <span className="bg-joule text-white text-sm px-3 py-1 rounded-full ml-2">
              {bookingsTableInstance.rows.length} bookings
            </span>
          </h2>
        </div>{" "}
        {/* Uniform text size */}
        <div className="overflow-auto max-h-[calc(100vh-12vh)] min-h-[calc(100vh-12vh)] shadow shadow-black rounded-lg mx-4">
          {" "}
          {/* Dynamic table length */}
          <table
            {...bookingsTableInstance.getTableProps()}
            className="min-w-full divide-y divide-gray-200"
          >
            <thead className="bg-joule text-white sticky top-0 z-10 h-12">
              {bookingsTableInstance.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-2 py-2 text-xs text-left font-medium text-white uppercase tracking-wider"
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...bookingsTableInstance.getTableBodyProps()}
              className="bg-white divide-y divide-gray-200"
            >
              {bookingsTableInstance.rows.map((row) => {
                bookingsTableInstance.prepareRow(row);
                const bgColorClass = getRowBackgroundColor(
                  row.original.bookingDate,
                  row.original.dueDate
                );

                return (
                  <tr
                    {...row.getRowProps()}
                    className={`${bgColorClass} hover:bg-gray-100`}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-2 py-4 text-sm whitespace-nowrap"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Modal for Service Bookings */}
        {modalData && modalData.source === "bookings" && (
          <ServiceCell
            isOpen={!!modalData}
            data={modalData}
            source={modalData.source}
            toggleModalVisibility={() => toggleModal(null)}
            markAsRepaired={markBookingAsRepaired}
          />
        )}
      </div>
      {/* Returns Table */}
      <div className="w-full lg:w-1/3">
        <div className="flex justify-center mt-2 mb-4">
          <h2 className="text-xl font-semibold text-center flex items-center">
            Return Repair
            <span className="bg-joule text-white text-sm px-3 py-1 rounded-full ml-2">
              {returnsTableInstance.rows.length} bikes
            </span>
          </h2>
        </div>
        <div className="overflow-auto max-h-[calc(100vh-12vh)] min-h-[calc(100vh-12vh)] shadow shadow-black rounded-lg mx-4">
          <table
            {...returnsTableInstance.getTableProps()}
            className="min-w-full divide-y divide-gray-200"
          >
            <thead className="bg-joule text-white sticky top-0 z-10 h-12">
              {returnsTableInstance.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                        index === 0 ? "rounded-tl-lg" : ""
                      } ${
                        index === headerGroup.headers.length - 1
                          ? "rounded-tr-lg"
                          : ""
                      }`}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...returnsTableInstance.getTableBodyProps()}
              className="bg-white divide-y divide-gray-200"
            >
              {returnsTableInstance.rows.map((row) => {
                returnsTableInstance.prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="hover:bg-gray-100">
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-2 py-4 text-sm whitespace-nowrap"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Modal for Returns */}
        {modalData && modalData.source === "returns" && (
          <ServiceCell
            isOpen={!!modalData}
            data={modalData}
            source={modalData.source}
            toggleModalVisibility={() => toggleModal(null)}
            markAsRepaired={markReturnAsRepaired}
          />
        )}
      </div>
    </div>
  );
};

export default TodoList;
