import React, { useState, useEffect } from "react";
import "../calendar.css";
import ReactModal from "react-modal";
import axios from "axios";

ReactModal.setAppElement("#root");

// Example: making the modal a bit larger, adding more padding
const modalStyles = {
  content: {
    maxWidth: "900px", // bumped from 700px
    width: "90%",
    margin: "auto",
    padding: "40px", // increased from 30px
    borderRadius: "12px",
    border: "4px solid #374151",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.6)",
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    zIndex: 1000,
  },
};

const ReturnConfirmationModal = ({
  isOpen,
  toggleModalVisibility,
  data,
  markAsReturned,
  markAsRepaired,
}) => {
  const [returnOption, setReturnOption] = useState("returnRepair");
  const [isMissing, setIsMissing] = useState(true);
  const [note, setNote] = useState("");

  // Numeric counts for each item
  const [missingCounts, setMissingCounts] = useState({
    charger: 0,
    adapter: 0,
    snakeLock: 0,
    foldingLock: 0,
    lockKey: 0,
    batteryKey: 0,
    reTyreSkins: 0,
    keyChain: 0,
  });

  // Holds data on previously-sent-out accessories
  const [originalAccessories, setOriginalAccessories] = useState({});

  // Fetch inventory data on mount if serialNumber is present
  useEffect(() => {
    if (data?.serialNumber) {
      axios
        .get(`/api/inventory/${data.serialNumber}`)
        .then((res) => {
          if (res.data && res.data.accessories) {
            setOriginalAccessories(res.data.accessories);
          }
        })
        .catch((err) => {
          console.error("Error fetching inventory item:", err);
        });
    }
  }, [data.serialNumber]);

  // Handle Bike status option
  const handleOptionChange = (option) => {
    setReturnOption(option);
  };

  // "Nothing is Missing" vs "Something is Missing"
  const handleMissingSelect = (missing) => {
    setIsMissing(missing);
    if (!missing) {
      // Reset all missing item counts to zero
      setMissingCounts({
        charger: 0,
        adapter: 0,
        snakeLock: 0,
        foldingLock: 0,
        lockKey: 0,
        batteryKey: 0,
        reTyreSkins: 0,
        keyChain: 0,
      });
    }
  };

  // Plus/Minus
  const incrementItem = (item) => {
    setMissingCounts((prev) => ({
      ...prev,
      [item]: prev[item] + 1,
    }));
  };

  const decrementItem = (item) => {
    setMissingCounts((prev) => ({
      ...prev,
      [item]: Math.max(prev[item] - 1, 0), // clamp at 0
    }));
  };

  // Submit handler
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Gather missing items
    const missingItems = Object.entries(missingCounts)
      .filter(([_, qty]) => qty > 0)
      .map(([key]) => key);

    try {
      // If user chose "Something Missing" and we have any items > 0, record them
      if (isMissing && missingItems.length > 0) {
        await axios.post("/api/missing-items", {
          serialNumber: data.serialNumber,
          fullName: `${data.firstName} ${data.lastName}`,
          phone: data.phone,
          email: data.email,
          itemSkuName: data.itemSkuName,
          orderId: data.orderId,
          subscriptionId: data.subscriptionId,
          missingItems,
          note,
          missingItemStatus: "open",
        });
      }

      // 1) Mark in Circuly as returned
      const returnResponse = await axios.post(
        `/api/mark-as-returned/${data.serialNumber}`
      );
      if (returnResponse.status !== 200)
        throw new Error("Failed to mark as returned");

      // 2) Mark local booking as returned
      await markAsReturned(data._id);

      // 3) Handle inStock vs returnRepair
      if (returnOption === "inStock") {
        const inStockResponse = await axios.post(
          `/api/set-in-stock/${data.serialNumber}`
        );
        if (inStockResponse.status !== 200)
          throw new Error("Failed to set as in stock");

        // Mark as repaired & set operational status
        await markAsRepaired(data._id);
        await axios.put(
          `/api/inventory/${data.serialNumber}/operationalStatus`,
          {
            operationalStatus: "in-stock",
          }
        );
      } else {
        // "returnRepair"
        await axios.put(
          `/api/inventory/${data.serialNumber}/operationalStatus`,
          {
            operationalStatus: "repair-returned",
          }
        );
      }

      toggleModalVisibility();
    } catch (error) {
      console.error("Error processing return:", error);
    }
  };

  // Disable if user says "Something is missing" but no item > 0
  const isSubmitDisabled =
    isMissing && !Object.values(missingCounts).some((qty) => qty > 0);

  // Accessory list for rendering
  const accessoriesList = [
    {
      key: "charger",
      label: "Charger",
      original: originalAccessories.charger || 0,
    },
    {
      key: "adapter",
      label: "Adapter",
      original: originalAccessories.adapter || 0,
    },
    {
      key: "snakeLock",
      label: "Snake Lock",
      original: originalAccessories.snakeLock || 0,
    },
    {
      key: "foldingLock",
      label: "Folding Lock",
      original: originalAccessories.foldingLock || 0,
    },
    {
      key: "lockKey",
      label: "Lock Key",
      original: originalAccessories.lockKey || 0,
    },
    {
      key: "batteryKey",
      label: "Battery Key",
      original: originalAccessories.batteryKey || 0,
    },
    {
      key: "reTyreSkins",
      label: "Retyre Skins",
      original: originalAccessories.reTyreSkins || 0,
    },
    {
      key: "keyChain",
      label: "Key Chain",
      original: originalAccessories.keyChain || 0,
    },
  ];

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleModalVisibility}
      contentLabel="Return Confirmation"
      style={modalStyles}
    >
      <div className="text-center mb-8">
        <h2 className="text-gray-900 text-2xl font-semibold mb-6">
          Return Confirmation - Bike {data.serialNumber}
        </h2>

        {/* Divider */}
        <hr className="my-6 border-t border-gray-300" />

        <form
          onSubmit={handleFormSubmit}
          className="bg-white rounded px-8 pt-6 pb-8 text-base"
        >
          {/* Set Bike Status */}
          <div className="mb-8">
            <h3 className="text-gray-700 font-semibold text-lg mb-4">
              Set Bike Status
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => handleOptionChange("inStock")}
                className={`w-full py-3 px-8 text-lg font-semibold rounded-lg shadow-md focus:outline-none ${
                  returnOption === "inStock"
                    ? "bg-joule text-white shadow-black shadow-md"
                    : "bg-gray-400 text-white hover:bg-joule hover:text-white"
                }`}
              >
                Set in Stock
              </button>
              <button
                type="button"
                onClick={() => handleOptionChange("returnRepair")}
                className={`w-full py-3 px-8 text-lg font-semibold rounded-lg shadow-md focus:outline-none ${
                  returnOption === "returnRepair"
                    ? "bg-joule text-white shadow-black shadow-md"
                    : "bg-gray-400 text-white hover:bg-joule hover:text-white"
                }`}
              >
                Put in Repair
              </button>
            </div>
          </div>

          {/* Missing Items Toggle */}
          <div>
            <h3 className="text-gray-700 font-semibold text-lg mb-4">
              Missing Items
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => handleMissingSelect(false)}
                className={`w-full py-3 px-8 text-lg font-semibold rounded-lg shadow-md focus:outline-none ${
                  !isMissing
                    ? "bg-joule text-white shadow-black shadow-md"
                    : "bg-gray-400 text-white hover:bg-joule hover:text-white"
                }`}
              >
                Nothing is Missing
              </button>
              <button
                type="button"
                onClick={() => handleMissingSelect(true)}
                className={`w-full py-3 px-8 text-lg font-semibold rounded-lg shadow-md focus:outline-none ${
                  isMissing
                    ? "bg-joule text-white shadow-black shadow-md"
                    : "bg-gray-400 text-white hover:bg-joule hover:text-white"
                }`}
              >
                Something is Missing
              </button>
            </div>
          </div>

          {/* Gray divider + extra spacing before and after */}
          <div style={{ margin: "32px 0" }}>
            <hr
              style={{
                border: "0",
                borderTop: "1px solid #e5e7eb", // Tailwind's gray-200
                height: "1px",
              }}
            />
          </div>

          {/* Missing Items Table */}
          {isMissing && (
            <div>
              <div className="overflow-x-auto">
                <table className="min-w-full border-collapse text-center rounded-t-md overflow-hidden">
                  <thead className="bg-gray-700 text-white">
                    <tr>
                      <th className="px-4 py-3 font-semibold text-sm uppercase tracking-wide">
                        Accessory
                      </th>
                      <th className="px-4 py-3 font-semibold text-sm uppercase tracking-wide">
                        Missing
                      </th>
                      <th className="px-4 py-3 font-semibold text-sm uppercase tracking-wide">
                        Previously Sent Out
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessoriesList.map(({ key, label, original }, idx) => (
                      <tr
                        key={key}
                        className={`border-b ${
                          idx % 2 === 1 ? "bg-gray-50" : ""
                        }`}
                      >
                        <td className="px-4 py-3 border-r">{label}</td>
                        {/* Plus/minus */}
                        <td className="px-4 py-3 border-r">
                          <div className="flex items-center justify-center">
                            <button
                              type="button"
                              className="h-10 w-10 bg-joule text-white font-extrabold text-lg rounded-l"
                              onClick={() => decrementItem(key)}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              readOnly
                              value={missingCounts[key]}
                              className="w-14 h-10 border-t border-b border-gray-300 text-center font-extrabold text-lg text-gray-800"
                            />
                            <button
                              type="button"
                              className="h-10 w-10 bg-joule text-white font-extrabold text-lg rounded-r"
                              onClick={() => incrementItem(key)}
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-3 font-extrabold">{original}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Note field in a subtle box */}
              <div className="bg-gray-50 p-4 rounded-md border border-gray-200 mt-6">
                <label
                  className="block text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="note"
                >
                  Note
                </label>
                <textarea
                  className="border-gray-600 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-joule focus:ring-1 focus:ring-joule"
                  id="note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Additional notes..."
                />
              </div>
            </div>
          )}

          {/* Divider */}
          <hr className="my-6 border-t border-gray-300" />

          {/* Extra spacing before action buttons */}
          <div style={{ height: "20px" }} />

          {/* Action buttons */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <button
              type="submit"
              className={`w-full py-3 px-8 text-lg font-semibold rounded-lg shadow-md focus:outline-none ${
                isSubmitDisabled
                  ? "bg-gray-500 text-white"
                  : "bg-joule text-white hover:bg-gray-900"
              }`}
              disabled={isSubmitDisabled}
            >
              Mark as Returned
            </button>
            <button
              type="button"
              className="w-full py-3 px-8 text-lg font-semibold rounded-lg shadow-md bg-red-500 text-white hover:bg-red-700 focus:outline-none"
              onClick={toggleModalVisibility}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default ReturnConfirmationModal;
