import React from "react";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-10 px-4 py-8 bg-gray-50">
      {/* Title */}
      <h1 className="text-3xl font-bold text-gray-800">
        Welcome to the Main Dashboard
      </h1>

      {/* Button Row */}
      <div className="flex flex-col sm:flex-row flex-wrap justify-center gap-6">
        {/* Order Dashboard Button */}
        <button
          onClick={() => navigate("/order-dashboard")}
          className="text-lg font-semibold px-8 py-4 rounded shadow-md
                     bg-[#FFDEC5] text-gray-800
                     hover:bg-[#FECDA4] hover:shadow-lg
                     focus:outline-none focus:ring-4 focus:ring-[#FECDA4]"
        >
          Order Dashboard
        </button>

        {/* Booking Dashboard Button */}
        <button
          onClick={() => navigate("/booking-dashboard")}
          className="text-lg font-semibold px-8 py-4 rounded shadow-md
                     bg-[#FCD34D] text-gray-800
                     hover:bg-[#FBBF24] hover:shadow-lg
                     focus:outline-none focus:ring-4 focus:ring-[#FBBF24]"
        >
          Booking Dashboard
        </button>

        {/* Return Dashboard Button */}
        <button
          onClick={() => navigate("/return-dashboard")}
          className="text-lg font-semibold px-8 py-4 rounded shadow-md
                     bg-[#E9D5FF] text-gray-800
                     hover:bg-[#D8B4FE] hover:shadow-lg
                     focus:outline-none focus:ring-4 focus:ring-[#D8B4FE]"
        >
          Return Dashboard
        </button>

        {/* Inventory Dashboard Button */}
        <button
          onClick={() => navigate("/inventory-dashboard")}
          className="text-lg font-semibold px-8 py-4 rounded shadow-md
                     bg-[#C1E4EA] text-gray-800
                     hover:bg-[#B2D9DF] hover:shadow-lg
                     focus:outline-none focus:ring-4 focus:ring-[#B2D9DF]"
        >
          Inventory Dashboard
        </button>

        {/* Service Dashboard Button */}
        <button
          onClick={() => navigate("/service-dashboard")}
          className="text-lg font-semibold px-8 py-4 rounded shadow-md
                     bg-[#A7F3D0] text-gray-800
                     hover:bg-[#6EE7B7] hover:shadow-lg
                     focus:outline-none focus:ring-4 focus:ring-[#6EE7B7]"
        >
          Service Dashboard
        </button>
      </div>
    </div>
  );
}
