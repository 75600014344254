// ServiceDashboard.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { DateTime } from "luxon";

/* 1) formatDate helper */
function formatDate(dt, timeframe) {
  let rawDate;
  let label;

  switch (timeframe) {
    case "daily":
      rawDate = dt.startOf("day");
      label = rawDate.toFormat("ccc, dd LLL");
      break;
    case "weekly":
      rawDate = dt.startOf("week");
      label = `Week ${dt.weekNumber} (${dt.year})`;
      break;
    case "monthly":
      rawDate = dt.startOf("month");
      label = rawDate.toFormat("LLLL yyyy");
      break;
    case "yearly":
      rawDate = dt.startOf("year");
      label = rawDate.toFormat("yyyy");
      break;
    default:
      rawDate = dt;
      label = dt.toISODate();
  }
  return { rawDate, label };
}

/* 2) generateEmptyChartData */
function generateEmptyChartData(timeframe, sDate, eDate, groupBy) {
  const start = DateTime.fromISO(sDate).startOf("day");
  const end = DateTime.fromISO(eDate).endOf("day");

  const incrementMapping = {
    daily: { days: 1 },
    weekly: { weeks: 1 },
    monthly: { months: 1 },
    yearly: { years: 1 },
  };

  // Possible statuses
  // --> Now includes "noType" or "noCategory"
  const serviceTypeStatuses = [
    "fromStock",
    "returnService",
    "bookedService",
    "bookedRepair",
    "prepareService",
    "noType", // NEW
  ];
  const serviceCategoryStatuses = [
    "correctiveService",
    "tireChange",
    "fullService",
    "standardService",
    "refresh",
    "noCategory", // NEW
  ];

  const statusesToUse =
    groupBy === "serviceType" ? serviceTypeStatuses : serviceCategoryStatuses;

  const emptyData = [];
  let cursor = start;

  while (cursor <= end) {
    const { rawDate, label } = formatDate(cursor, timeframe);

    const counters = {};
    statusesToUse.forEach((st) => {
      counters[st] = 0;
    });

    emptyData.push({
      rawDate,
      bucket: label,
      ...counters,
    });

    cursor = cursor.plus(incrementMapping[timeframe]);
  }

  return emptyData;
}

/* 3) groupServicesByTime */
function groupServicesByTime(services, timeframe, groupBy, sDate, eDate) {
  const baseline = generateEmptyChartData(timeframe, sDate, eDate, groupBy);
  const baselineMap = {};

  baseline.forEach((entry, idx) => {
    baselineMap[entry.rawDate.toISO()] = idx;
  });

  const start = DateTime.fromISO(sDate).startOf("day");
  const end = DateTime.fromISO(eDate).endOf("day");

  services.forEach((svc) => {
    const dt = DateTime.fromISO(svc.serviceDate, { zone: "utc" }).toLocal();
    if (!dt.isValid) return;
    if (dt < start || dt > end) return;

    const { rawDate } = formatDate(dt, timeframe);
    const idx = baselineMap[rawDate.toISO()];
    if (idx === undefined) return;

    if (groupBy === "serviceType") {
      switch (svc.serviceType) {
        case "Prepare Service":
          baseline[idx].prepareService += 1;
          break;
        case "Booked Service":
          baseline[idx].bookedService += 1;
          break;
        case "Booked Repair":
          baseline[idx].bookedRepair += 1;
          break;
        case "Return Service":
          baseline[idx].returnService += 1;
          break;
        case "From stock":
          baseline[idx].fromStock += 1;
          break;
        default:
          // If it's missing or unrecognized, increment noType
          baseline[idx].noType += 1;
          break;
      }
    } else {
      // groupBy === "serviceCategory"
      switch (svc.serviceCategory) {
        case "refresh":
          baseline[idx].refresh += 1;
          break;
        case "standard service":
          baseline[idx].standardService += 1;
          break;
        case "full service":
          baseline[idx].fullService += 1;
          break;
        case "tire change":
          baseline[idx].tireChange += 1;
          break;
        case "corrective service":
          baseline[idx].correctiveService += 1;
          break;
        default:
          // If it's missing or unrecognized, increment noCategory
          baseline[idx].noCategory += 1;
          break;
      }
    }
  });

  return baseline;
}

/* 4) getTopPartsUsed */
function getTopPartsUsed(services, topN = 15) {
  const partCounts = {};

  services.forEach((svc) => {
    if (!svc.partsUsed) return;
    svc.partsUsed.forEach((part) => {
      // Use only productDescription
      const key = part.productDescription || "Unknown Part";
      partCounts[key] = (partCounts[key] || 0) + (part.quantity || 0);
    });
  });

  return Object.entries(partCounts)
    .map(([desc, totalQuantity]) => ({ partDescription: desc, totalQuantity }))
    .sort((a, b) => b.totalQuantity - a.totalQuantity)
    .slice(0, topN);
}

/* 
  5) FilteredLegend 
  - Now includes "noType" and "noCategory" in the relevant arrays
  - Reverses them so the topmost bar appears first
*/
function FilteredLegend({ payload, groupBy }) {
  if (!payload) return null;

  const serviceTypeKeys = [
    "fromStock",
    "returnService",
    "bookedService",
    "bookedRepair",
    "prepareService",
    "noType",
  ];
  const serviceCategoryKeys = [
    "correctiveService",
    "tireChange",
    "fullService",
    "standardService",
    "refresh",
    "noCategory",
  ];

  // Keep items that match the current group
  let filtered = payload;
  if (groupBy === "serviceType") {
    filtered = payload.filter((item) => serviceTypeKeys.includes(item.dataKey));
  } else {
    filtered = payload.filter((item) =>
      serviceCategoryKeys.includes(item.dataKey)
    );
  }

  // Reverse so top bar is listed first
  const reversed = [...filtered].reverse();

  // Add some marginBottom to push the chart downward
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        justifyContent: "center",
        marginBottom: "2rem",
      }}
    >
      {reversed.map((entry) => (
        <div key={entry.value} style={{ color: entry.color, fontWeight: 500 }}>
          <span style={{ marginRight: 6 }}>■</span>
          {entry.value}
        </div>
      ))}
    </div>
  );
}

/* 6) ServiceDashboard */
export default function ServiceDashboard() {
  const [services, setServices] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [topParts, setTopParts] = useState([]);

  const [groupBy, setGroupBy] = useState("serviceType"); // or "serviceCategory"
  const [timeframe, setTimeframe] = useState("daily");

  // Last month -> today
  const [startDate, setStartDate] = useState(
    DateTime.local().minus({ months: 1 }).toISODate()
  );
  const [endDate, setEndDate] = useState(DateTime.local().toISODate());

  // Fetch services once
  useEffect(() => {
    async function fetchServices() {
      try {
        const response = await axios.get("/api/services");
        if (Array.isArray(response.data)) {
          setServices(response.data);
        } else {
          setServices([]);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    }
    fetchServices();
  }, []);

  // Build chart + topParts
  useEffect(() => {
    if (!services.length) {
      setChartData(
        generateEmptyChartData(timeframe, startDate, endDate, groupBy)
      );
      setTopParts([]);
      return;
    }

    const data = groupServicesByTime(
      services,
      timeframe,
      groupBy,
      startDate,
      endDate
    );
    setChartData(data);

    const parts = getTopPartsUsed(services);
    setTopParts(parts);
  }, [services, timeframe, groupBy, startDate, endDate]);

  return (
    <div className="w-full min-h-screen px-4 pt-8 pb-11">
      <div className="bg-white shadow-sm border rounded-lg p-6 space-y-6 h-full">
        <h1 className="text-2xl font-bold mb-2 text-gray-800">
          Services Dashboard
        </h1>

        {/* Controls */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div className="flex items-center space-x-2">
            <label htmlFor="groupBy" className="font-semibold text-gray-700">
              Group By:
            </label>
            <select
              id="groupBy"
              value={groupBy}
              onChange={(e) => setGroupBy(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="serviceType">Service Type</option>
              <option value="serviceCategory">Service Category</option>
            </select>
          </div>

          <div className="flex items-center space-x-2">
            <label htmlFor="timeframe" className="font-semibold text-gray-700">
              Timeframe:
            </label>
            <select
              id="timeframe"
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <div className="flex flex-col">
              <label
                htmlFor="startDate"
                className="text-sm font-semibold text-gray-700 mb-1"
              >
                Start Date
              </label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="endDate"
                className="text-sm font-semibold text-gray-700 mb-1"
              >
                End Date
              </label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Main content: Chart (2/3) + Top Parts (1/3) */}
        <div className="flex flex-col lg:flex-row gap-6">
          {/* 2/3 Chart */}
          <div className="lg:w-2/3 w-full h-[1000px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                margin={{ top: 80, right: 20, left: 20, bottom: 100 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                <XAxis
                  dataKey="bucket"
                  stroke="#4B5563"
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  tickMargin={10}
                />
                <YAxis stroke="#4B5563" />
                <Tooltip />

                {/* 
                  Custom legend that filters out bars belonging to the "other" group,
                  plus includes marginBottom to separate it from the chart. 
                */}
                <Legend
                  content={(props) => (
                    <FilteredLegend groupBy={groupBy} {...props} />
                  )}
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                  wrapperStyle={{ paddingBottom: "40px" }}
                />

                {/* ========== SERVICE TYPE BARS ========== */}
                <Bar
                  dataKey="fromStock"
                  name="From stock"
                  fill="#A7F3D0"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="returnService"
                  name="Return Service"
                  fill="#FCA5A5"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="bookedService"
                  name="Booked Service"
                  fill="#93C5FD"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="bookedRepair"
                  name="Booked Repair"
                  fill="#FCA27E" // gentle alert orange-red
                  stackId="services"
                />
                <Bar
                  dataKey="prepareService"
                  name="Prepare Service"
                  fill="#FDE68A"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="noType"
                  name="No Type"
                  fill="#E5E7EB"
                  stackId="services"
                ></Bar>

                {/* ========== SERVICE CATEGORY BARS ========== */}
                <Bar
                  dataKey="correctiveService"
                  name="Corrective Service"
                  fill="#FCA5A5"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="tireChange"
                  name="Tire Change"
                  fill="#9CA3AF"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="fullService"
                  name="Full Service"
                  fill="#FDE68A"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="standardService"
                  name="Standard Service"
                  fill="#93C5FD"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="refresh"
                  name="Refresh"
                  fill="#C1E4EA"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="noCategory"
                  name="No Category"
                  fill="#E5E7EB"
                  stackId="services"
                ></Bar>
                <Bar
                  dataKey="__invisible__"
                  fill="transparent"
                  stackId="services"
                >
                  <LabelList
                    dataKey={(d) =>
                      Object.keys(d)
                        .filter((k) => !["rawDate", "bucket"].includes(k))
                        .reduce((sum, k) => sum + (d[k] || 0), 0)
                    }
                    position="top"
                    formatter={(val) => (val > 0 ? val : "")}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* 1/3: Top Parts */}
          <div className="lg:w-1/3 w-full overflow-auto">
            <h2 className="text-xl font-semibold mb-4">Top 15 Parts Used</h2>
            <table className="min-w-full border border-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 border-b text-left font-medium text-gray-700">
                    Part Description
                  </th>
                  <th className="px-4 py-2 border-b text-left font-medium text-gray-700">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                {topParts.map((item, idx) => (
                  <tr key={idx}>
                    <td className="px-4 py-2 border-b text-gray-700">
                      {item.partDescription}
                    </td>
                    <td className="px-4 py-2 border-b text-gray-700">
                      {item.totalQuantity}
                    </td>
                  </tr>
                ))}
                {topParts.length === 0 && (
                  <tr>
                    <td
                      colSpan="2"
                      className="px-4 py-2 text-center text-gray-500"
                    >
                      No parts used
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
