import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, useSortBy } from "react-table";
import axios from "axios";

const OutgoingBikes = () => {
  const [outgoingOrdersData, setOutgoingOrdersData] = useState([]);
  const [outgoingBookingsData, setOutgoingBookingsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateField = useCallback(
    async (id, field, value, source) => {
      try {
        let url = `/api/${source}/${id}`;
        const response = await axios.put(url, { [field]: value });
        if (response.status === 200) {
          switch (source) {
            case "orders":
              setOutgoingOrdersData(
                outgoingOrdersData.map((item) =>
                  item._id === id ? { ...item, [field]: value } : item
                )
              );
              break;
            case "bookings":
              setOutgoingBookingsData(
                outgoingBookingsData.map((item) =>
                  item._id === id ? { ...item, [field]: value } : item
                )
              );
              break;
            default:
              throw new Error(`Unknown source: ${source}`);
          }
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [outgoingOrdersData, outgoingBookingsData]
  );

  const updateInventoryOperationalStatus = useCallback(
    async (serialNumber, newStatus) => {
      try {
        await axios.put(`/api/inventory/${serialNumber}/operationalStatus`, {
          operationalStatus: newStatus,
        });
      } catch (error) {
        console.error("Error updating inventory operational status:", error);
      }
    },
    []
  );

  const outgoingOrdersColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "orderShippingMethod",
      },
      {
        Header: "Delivery Date",
        accessor: "orderTagDate",
        Cell: ({ value }) =>
          value
            ? new Date(value).toLocaleDateString("nb-NO").replace(/\//g, ".")
            : "not set",
        sortType: (rowA, rowB) => {
          const dateA = rowA.original.orderTagDate;
          const dateB = rowB.original.orderTagDate;

          if (!dateA) return 1; // Place null values at the bottom
          if (!dateB) return -1;

          return new Date(dateA) - new Date(dateB);
        },
      },
      { Header: "Delivery Time", accessor: "deliveryTime" },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = async () => {
            let newStatus = "";
            switch (row.original.orderStatus) {
              case "ready for customer":
                newStatus = "fulfilled";
                try {
                  await axios.post(
                    `/api/circuly-orders/${row.original.orderId}/fulfill`
                  );
                  await axios.put(
                    `/api/circuly-orders/${row.original.orderId}/tag`,
                    { tag: "delivered" }
                  );
                  await updateInventoryOperationalStatus(
                    row.original.serialNumber,
                    "with customer"
                  );
                  await updateField(
                    row.original._id,
                    "orderStatus",
                    newStatus,
                    "orders"
                  );
                } catch (error) {
                  console.error("Error processing order fulfillment:", error);
                }
                break;
              default:
                break;
            }
          };

          let buttonText =
            row.original.orderStatus === "ready for customer"
              ? "Mark as fulfilled"
              : "";

          return buttonText ? (
            <button
              onClick={handleActionClick}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              {buttonText}
            </button>
          ) : null;
        },
      },
    ],
    [updateField, updateInventoryOperationalStatus]
  );

  const outgoingBookingsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "deliveryTypeToCustomer",
      },
      {
        Header: "Return Date",
        accessor: "returnDate",
        Cell: ({ value }) =>
          value
            ? new Date(value).toLocaleDateString("nb-NO").replace(/\//g, ".")
            : "not set",
        sortType: (rowA, rowB) => {
          const dateA = rowA.original.returnDate;
          const dateB = rowB.original.returnDate;

          if (!dateA) return 1; // Place null values at the bottom
          if (!dateB) return -1;

          return new Date(dateA) - new Date(dateB);
        },
      },
      { Header: "Return Time", accessor: "returnTime" },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = async () => {
            const {
              _id,
              serialNumber,
              bookingStatus,
              deliveryTypeToCustomer,
              returnDate,
            } = row.original;

            if (bookingStatus === "ready for customer") {
              try {
                // Set deliveryTypeToCustomer to "nydalen" if not set
                if (!deliveryTypeToCustomer) {
                  await updateField(
                    _id,
                    "deliveryTypeToCustomer",
                    "nydalen",
                    "bookings"
                  );
                }

                // Set returnDate to today's date if not set
                if (!returnDate) {
                  await updateField(
                    _id,
                    "returnDate",
                    new Date().toISOString(),
                    "bookings"
                  );
                }

                await updateInventoryOperationalStatus(
                  serialNumber,
                  "with customer"
                );
                await updateField(
                  _id,
                  "bookingStatus",
                  "returned to customer",
                  "bookings"
                );
              } catch (error) {
                console.error("Error in updating booking status:", error);
              }
            }
          };

          let buttonText =
            row.original.bookingStatus === "ready for customer"
              ? "Returned to customer"
              : "";

          return buttonText ? (
            <button
              onClick={handleActionClick}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              {buttonText}
            </button>
          ) : null;
        },
      },
    ],
    [updateField, updateInventoryOperationalStatus]
  );

  const compareDateToToday = (dateString) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to start of the day
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0); // Normalize the compared date

    if (date.getTime() === today.getTime()) {
      return "today";
    } else if (date < today) {
      return "past";
    } else {
      return "future";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const ordersResponse = await axios.get("/api/orders");
        const filteredOrders = ordersResponse.data.filter(
          (order) =>
            order.orderStatus === "new" ||
            order.orderStatus === "ready for customer"
        );
        setOutgoingOrdersData(filteredOrders);

        const bookingsResponse = await axios.get("/api/bookings");
        const outgoingBookings = bookingsResponse.data.filter(
          (booking) => booking.bookingStatus === "ready for customer"
        );
        setOutgoingBookingsData(outgoingBookings);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const outgoingOrdersTableInstance = useTable(
    {
      columns: outgoingOrdersColumns,
      data: outgoingOrdersData,
      initialState: { sortBy: [{ id: "orderTagDate", desc: false }] },
    },
    useSortBy
  );

  const outgoingBookingsTableInstance = useTable(
    {
      columns: outgoingBookingsColumns,
      data: outgoingBookingsData,
      initialState: { sortBy: [{ id: "returnDate", desc: false }] },
    },
    useSortBy
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow p-4 h-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Outgoing Bikes Table (New and Ready-for-customer Orders) */}
        <div className="flex flex-col w-full h-full">
          <div className="flex justify-center mt-2 mb-4">
            <h2 className="text-xl font-semibold text-center flex items-center">
              Outgoing Bikes - New Orders
              <span className="bg-joule text-white text-sm px-3 py-1 rounded-full ml-2">
                {outgoingOrdersTableInstance.rows.length} new orders
              </span>
            </h2>
          </div>

          <div className="overflow-auto max-h-[calc(100vh-10vh)] min-h-[calc(100vh-10vh)] shadow shadow-black rounded-lg">
            <table
              {...outgoingOrdersTableInstance.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-joule text-white sticky top-0 z-10">
                {outgoingOrdersTableInstance.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                          index === 0 ? "rounded-tl-lg" : ""
                        } ${
                          index === headerGroup.headers.length - 1
                            ? "rounded-tr-lg"
                            : ""
                        }`}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...outgoingOrdersTableInstance.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {outgoingOrdersTableInstance.rows.map((row) => {
                  outgoingOrdersTableInstance.prepareRow(row);
                  const rowDateStatus = compareDateToToday(
                    row.original.orderTagDate
                  );
                  const rowClassName =
                    rowDateStatus === "today"
                      ? "bg-yellow-500 bg-opacity-50"
                      : rowDateStatus === "past"
                      ? "bg-red-500 bg-opacity-50"
                      : "";
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`hover:bg-gray-100 ${rowClassName}`}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-4 text-sm whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Outgoing Bikes Table (Finished Service Bookings) */}
        <div className="flex flex-col w-full h-full">
          <div className="flex justify-center mt-2 mb-4">
            <h2 className="text-xl font-semibold text-center flex items-center">
              Outgoing Bikes - Finished service
              <span className="bg-joule text-white text-sm px-3 py-1 rounded-full ml-2">
                {outgoingBookingsTableInstance.rows.length} finished services
              </span>
            </h2>
          </div>
          <div className="overflow-auto max-h-[calc(100vh-10vh)] min-h-[calc(100vh-10vh)] shadow shadow-black rounded-lg">
            <table
              {...outgoingBookingsTableInstance.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-joule text-white sticky top-0 z-10">
                {outgoingBookingsTableInstance.headerGroups.map(
                  (headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                            index === 0 ? "rounded-tl-lg" : ""
                          } ${
                            index === headerGroup.headers.length - 1
                              ? "rounded-tr-lg"
                              : ""
                          }`}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                )}
              </thead>
              <tbody
                {...outgoingBookingsTableInstance.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {outgoingBookingsTableInstance.rows.map((row) => {
                  outgoingBookingsTableInstance.prepareRow(row);
                  const rowDateStatus = compareDateToToday(
                    row.original.returnDate
                  );
                  const rowClassName =
                    rowDateStatus === "today"
                      ? "bg-yellow-500 bg-opacity-50"
                      : rowDateStatus === "past"
                      ? "bg-red-500 bg-opacity-50"
                      : "";
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`hover:bg-gray-100 ${rowClassName}`}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-4 text-sm whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutgoingBikes;
