// BookingDashboard.jsx

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { DateTime } from "luxon";

/* 1) formatDate(dt, timeframe) */
function formatDate(dt, timeframe) {
  let rawDate;
  let label;
  switch (timeframe) {
    case "daily":
      rawDate = dt.startOf("day");
      label = rawDate.toFormat("ccc, dd LLL");
      break;
    case "weekly":
      rawDate = dt.startOf("week");
      label = `Week ${dt.weekNumber} (${dt.year})`;
      break;
    case "monthly":
      rawDate = dt.startOf("month");
      label = rawDate.toFormat("LLLL yyyy");
      break;
    case "yearly":
      rawDate = dt.startOf("year");
      label = rawDate.toFormat("yyyy");
      break;
    default:
      rawDate = dt;
      label = dt.toISODate();
  }
  return { rawDate, label };
}

/* 2) generateEmptyChartData(...) */
function generateEmptyChartData(timeframe, sDate, eDate, groupBy) {
  const start = DateTime.fromISO(sDate).startOf("day");
  const end = DateTime.fromISO(eDate).endOf("day");

  const incrementMapping = {
    daily: { days: 1 },
    weekly: { weeks: 1 },
    monthly: { months: 1 },
    yearly: { years: 1 },
  };

  const bookingTypes = ["Dekkskifte", "Service", "Reparasjon", "Vinterfrys"];
  const bookingStatuses = [
    "new",
    "received",
    "ready for customer",
    "returned to customer",
  ];

  const statusesToUse = groupBy === "type" ? bookingTypes : bookingStatuses;

  const emptyData = [];
  let cursor = start;
  while (cursor <= end) {
    const { rawDate, label } = formatDate(cursor, timeframe);

    const counters = {};
    statusesToUse.forEach((st) => {
      counters[st] = 0;
    });

    emptyData.push({
      rawDate,
      bucket: label,
      ...counters,
    });

    cursor = cursor.plus(incrementMapping[timeframe]);
  }
  return emptyData;
}

/* 3) groupBookingsByTime(...) */
function groupBookingsByTime(bookings, timeframe, groupBy, sDate, eDate) {
  const baseline = generateEmptyChartData(timeframe, sDate, eDate, groupBy);
  const baselineMap = {};

  baseline.forEach((entry, idx) => {
    baselineMap[entry.rawDate.toISO()] = idx;
  });

  const start = DateTime.fromISO(sDate).startOf("day");
  const end = DateTime.fromISO(eDate).endOf("day");

  bookings.forEach((bk) => {
    if (!bk.bookingDate) return;
    const dt = DateTime.fromISO(bk.bookingDate, { zone: "utc" }).toLocal();
    if (!dt.isValid) return;
    if (dt < start || dt > end) return;

    const { rawDate } = formatDate(dt, timeframe);
    const idx = baselineMap[rawDate.toISO()];
    if (idx === undefined) return;

    // Convert "winter_freeze" => "Vinterfrys"
    let bookingType = bk.type === "winter_freeze" ? "Vinterfrys" : bk.type;

    if (groupBy === "type") {
      // Dekkskifte, Service, Reparasjon, Vinterfrys
      switch (bookingType) {
        case "Dekkskifte":
          baseline[idx].Dekkskifte += 1;
          break;
        case "Service":
          baseline[idx].Service += 1;
          break;
        case "Reparasjon":
          baseline[idx].Reparasjon += 1;
          break;
        case "Vinterfrys":
          baseline[idx].Vinterfrys += 1;
          break;
        default:
          // unknown type => skip
          break;
      }
    } else {
      // groupBy === "bookingStatus"
      // new, received, ready for customer, returned to customer
      switch (bk.bookingStatus) {
        case "new":
          baseline[idx].new += 1;
          break;
        case "received":
          baseline[idx].received += 1;
          break;
        case "ready for customer":
          baseline[idx]["ready for customer"] += 1;
          break;
        case "returned to customer":
          baseline[idx]["returned to customer"] += 1;
          break;
        default:
          // unknown status => skip
          break;
      }
    }
  });

  return baseline;
}

/* 4) FilteredLegend
   - Filter out the "other" group's bars from the legend
   - Reverse so the top bar is listed first
*/
function FilteredLegend({ payload, groupBy }) {
  if (!payload) return null;

  const bookingTypeKeys = ["Dekkskifte", "Service", "Reparasjon", "Vinterfrys"];
  const bookingStatusKeys = [
    "new",
    "received",
    "ready for customer",
    "returned to customer",
  ];

  let filtered = payload;
  if (groupBy === "type") {
    // Keep only type-based bars
    filtered = payload.filter((item) => bookingTypeKeys.includes(item.dataKey));
  } else {
    // Keep only status-based bars
    filtered = payload.filter((item) =>
      bookingStatusKeys.includes(item.dataKey)
    );
  }

  // Reverse them so the "top" bar is listed first
  const reversed = [...filtered].reverse();

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        justifyContent: "center",
        marginBottom: "2rem",
      }}
    >
      {reversed.map((entry) => (
        <div key={entry.value} style={{ color: entry.color, fontWeight: 500 }}>
          <span style={{ marginRight: 6 }}>■</span>
          {entry.value}
        </div>
      ))}
    </div>
  );
}

/* 5) BookingDashboard */
export default function BookingDashboard() {
  const [bookings, setBookings] = useState([]);
  const [chartData, setChartData] = useState([]);

  // Let user pick grouping
  const [groupBy, setGroupBy] = useState("type"); // or "bookingStatus"
  const [timeframe, setTimeframe] = useState("daily");

  // 2 weeks before -> 2 weeks after
  const [startDate, setStartDate] = useState(
    DateTime.local().minus({ weeks: 2 }).toISODate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local().plus({ weeks: 2 }).toISODate()
  );

  // Fetch bookings
  useEffect(() => {
    async function fetchBookings() {
      try {
        const response = await axios.get("/api/bookings");
        if (Array.isArray(response.data)) {
          setBookings(response.data);
        } else {
          setBookings([]);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    }
    fetchBookings();
  }, []);

  // Build chart data
  useEffect(() => {
    if (!bookings.length) {
      setChartData(
        generateEmptyChartData(timeframe, startDate, endDate, groupBy)
      );
      return;
    }

    const grouped = groupBookingsByTime(
      bookings,
      timeframe,
      groupBy,
      startDate,
      endDate
    );
    setChartData(grouped);
  }, [bookings, timeframe, groupBy, startDate, endDate]);

  return (
    <div className="w-full min-h-screen px-4 pt-8 pb-11">
      <div className="bg-white shadow-sm border rounded-lg p-6 space-y-6 h-full">
        <h1 className="text-2xl font-bold mb-2 text-gray-800">
          Booking Dashboard
        </h1>

        {/* Controls */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          {/* Group By */}
          <div className="flex items-center space-x-2">
            <label htmlFor="groupBy" className="font-semibold text-gray-700">
              Group By:
            </label>
            <select
              id="groupBy"
              value={groupBy}
              onChange={(e) => setGroupBy(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="type">Booking Type</option>
              <option value="bookingStatus">Booking Status</option>
            </select>
          </div>

          {/* Timeframe */}
          <div className="flex items-center space-x-2">
            <label htmlFor="timeframe" className="font-semibold text-gray-700">
              Timeframe:
            </label>
            <select
              id="timeframe"
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          {/* Date Range */}
          <div className="flex items-center space-x-4">
            <div className="flex flex-col">
              <label
                htmlFor="startDate"
                className="text-sm font-semibold text-gray-700 mb-1"
              >
                Start Date
              </label>
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="endDate"
                className="text-sm font-semibold text-gray-700 mb-1"
              >
                End Date
              </label>
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Chart */}
        <div className="w-full h-[1000px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{ top: 60, right: 20, left: 20, bottom: 80 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
              <XAxis
                dataKey="bucket"
                stroke="#4B5563"
                interval={0}
                angle={-45}
                textAnchor="end"
                tickMargin={10}
              />
              <YAxis stroke="#4B5563" />
              <Tooltip />
              {/* FilteredLegend so we see only relevant items */}
              <Legend
                content={(props) => (
                  <FilteredLegend groupBy={groupBy} {...props} />
                )}
                layout="horizontal"
                verticalAlign="top"
                align="center"
                wrapperStyle={{ paddingBottom: "40px" }}
              />
              {/* Booking Types */}
              <Bar
                dataKey="Dekkskifte"
                name="Dekkskifte"
                fill="#60A5FA"
                stackId="bookings"
              />{" "}
              <Bar
                dataKey="Service"
                name="Service"
                fill="#34D399"
                stackId="bookings"
              />{" "}
              <Bar
                dataKey="Reparasjon"
                name="Reparasjon"
                fill="#EF4444"
                stackId="bookings"
              />{" "}
              <Bar
                dataKey="Vinterfrys"
                name="Vinterfrys"
                fill="#7DD3FC"
                stackId="bookings"
              />{" "}
              {/* Booking Status */}
              <Bar
                dataKey="new"
                name="New"
                fill="#D1D5DB"
                stackId="bookings"
              />{" "}
              <Bar
                dataKey="received"
                name="Received"
                fill="#2563EB"
                stackId="bookings"
              />{" "}
              <Bar
                dataKey="ready for customer"
                name="Ready for Customer"
                fill="#FBBF24"
                stackId="bookings"
              />{" "}
              <Bar
                dataKey="returned to customer"
                name="Returned to Customer"
                fill="#10B981"
                stackId="bookings"
              />{" "}
              {/* Invisible bar for total sum */}
              <Bar
                dataKey="__invisible__"
                fill="transparent"
                stackId="bookings"
              >
                <LabelList
                  dataKey={(d) =>
                    Object.keys(d)
                      .filter((k) => !["rawDate", "bucket"].includes(k))
                      .reduce((sum, k) => sum + (d[k] || 0), 0)
                  }
                  position="top"
                  formatter={(val) => (val > 0 ? val : "")}
                  offset={15}
                  style={{ fontWeight: "bold" }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
