import React, { useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";

ReactModal.setAppElement("#root"); // Ensure your root element is here

const modalStyles = {
  content: {
    maxWidth: "700px",
    width: "90%",
    margin: "auto",
    padding: "30px",
    borderRadius: "12px",
    border: "4px solid #374151",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.6)",
    overflow: "auto",
    backgroundColor: "#f7fafc", // Lighter background to highlight content
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    zIndex: 1000,
  },
};

const CreateOrderNoteModal = ({
  isOpen,
  toggleModalVisibility,
  onSuccess, // callback to parent if we want to update local data or show notification
  onError, // callback to parent if we want to show an error message
}) => {
  const [orderId, setOrderId] = useState("");
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateNote = async () => {
    try {
      setIsLoading(true);
      // POST to /api/orderNotes
      const response = await axios.post("/api/orderNotes", {
        orderId,
        note,
      });
      // if successful, notify parent
      if (onSuccess) {
        onSuccess(response.data);
      }
      // close modal
      toggleModalVisibility();
    } catch (error) {
      console.error("Error creating order note:", error);
      if (onError) {
        onError("Failed to create note. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    toggleModalVisibility();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleModalVisibility}
      contentLabel="Create Order Note"
      style={modalStyles}
    >
      <div className="space-y-6">
        {/* Title */}
        <div className="text-center mb-2">
          <h2 className="text-gray-900 text-3xl font-bold">Create Note</h2>
          <p className="text-gray-600 mt-1">
            Enter order ID and your note for the mechanics.
          </p>
        </div>

        <hr className="border-t border-gray-300" />

        {/* Form Fields */}
        <div className="bg-white rounded shadow-sm p-4 space-y-4">
          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">
              Order ID
            </label>
            <input
              type="text"
              className="border-gray-300 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-1 focus:ring-joule"
              placeholder="e.g. 12345"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </div>

          <div>
            <label className="block text-sm font-semibold text-gray-700 mb-1">
              Note
            </label>
            <textarea
              className="border-gray-300 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-1 focus:ring-joule"
              rows="3"
              placeholder="Write any special instructions here..."
              value={note}
              onChange={(e) => setNote(e.target.value)}
            ></textarea>
          </div>
        </div>

        <hr className="border-t border-gray-300" />

        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row justify-center sm:justify-between space-y-4 sm:space-y-0 mt-2">
          <button
            type="button"
            onClick={handleCreateNote}
            disabled={isLoading || !orderId}
            className={`w-full sm:w-auto py-2 px-6 font-semibold rounded-lg shadow-md focus:outline-none flex items-center justify-center bg-joule text-white hover:bg-gray-900 ${
              isLoading ? "cursor-not-allowed" : ""
            }`}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                Creating...
              </>
            ) : (
              "Create Note"
            )}
          </button>
          <button
            type="button"
            className="w-full sm:w-auto py-2 px-6 font-semibold rounded-lg shadow-md bg-red-500 text-white hover:bg-red-700 focus:outline-none"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default CreateOrderNoteModal;
