import React, { useEffect, useState } from "react";
import axios from "axios";

const InventoryDashboard = () => {
  const [matrixData, setMatrixData] = useState({});
  const [loading, setLoading] = useState(true);
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [totals, setTotals] = useState({});

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await axios.get("/api/inventory");
        const inventoryItems = response.data || [];
        const processedData = processInventoryDataForMatrix(inventoryItems);
        setMatrixData(processedData.data);
        setUniqueStatuses(processedData.statuses);
        setTotals(calculateTotals(processedData.data, processedData.statuses));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching inventory:", error);
        setLoading(false);
      }
    };

    fetchInventory();
  }, []);

  const processInventoryDataForMatrix = (inventoryItems) => {
    const data = {};
    const statuses = new Set();

    inventoryItems.forEach((item) => {
      const SKU = item.model.SKU;
      const status = item.status.operationalStatus;

      statuses.add(status);

      if (!data[SKU]) {
        data[SKU] = {};
      }

      if (!data[SKU][status]) {
        data[SKU][status] = 1;
      } else {
        data[SKU][status] += 1;
      }
    });

    return { data, statuses: Array.from(statuses).sort() };
  };

  const calculateTotals = (data, statuses) => {
    const columnTotals = {};
    const rowTotals = {};

    statuses.forEach((status) => (columnTotals[status] = 0));

    Object.keys(data).forEach((SKU) => {
      rowTotals[SKU] = 0;
      statuses.forEach((status) => {
        rowTotals[SKU] += data[SKU][status] || 0;
        columnTotals[status] += data[SKU][status] || 0;
      });
    });

    return { columnTotals, rowTotals };
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-opacity-75"></div>
      </div>
    );
  }

  const gridTemplateColumns = () => {
    return `minmax(120px, 1fr) repeat(${uniqueStatuses.length}, minmax(100px, 1fr)) minmax(80px, 1fr)`;
  };

  return (
    <div className="flex-1 flex flex-col p-4 h-screen bg-white">
      {/* Full Table Container */}
      <div className="overflow-auto flex-grow bg-white shadow rounded-lg h-full w-full">
        <table
          className="min-w-full w-full border-collapse"
          style={{ tableLayout: "fixed", height: "100%" }}
        >
          {/* Table Header */}
          <thead className="bg-joule text-white sticky top-0 z-10">
            <tr
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplateColumns(),
              }}
            >
              <th className="px-4 py-3 border border-joule text-center">SKU</th>
              {uniqueStatuses.map((status) => (
                <th
                  key={status}
                  className="px-4 py-3 border border-joule text-center"
                >
                  {status}
                </th>
              ))}
              <th className="px-4 py-3 border border-joule text-center">
                Total
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody
            style={{
              display: "block",
              maxHeight: "calc(100vh - 120px)", // Adjust based on expected height of header/footer
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {Object.keys(matrixData)
              .sort()
              .map((SKU) => (
                <tr
                  key={SKU}
                  style={{
                    display: "grid",
                    gridTemplateColumns: gridTemplateColumns(),
                  }}
                  className="border-b hover:bg-gray-50"
                >
                  <td className="px-4 py-4 text-sm font-medium text-gray-700 text-center border border-gray-300">
                    {SKU}
                  </td>
                  {uniqueStatuses.map((status) => (
                    <td
                      key={status}
                      className="px-4 py-4 text-sm text-gray-600 text-center border border-gray-300"
                    >
                      {matrixData[SKU][status] || 0}
                    </td>
                  ))}
                  <td className="px-4 py-4 text-sm font-medium text-gray-800 text-center border border-gray-300">
                    {totals.rowTotals[SKU]}
                  </td>
                </tr>
              ))}
          </tbody>

          {/* Table Footer */}
          <tfoot className="bg-joule text-white sticky bottom-0 z-10">
            <tr
              style={{
                display: "grid",
                gridTemplateColumns: gridTemplateColumns(),
              }}
            >
              <td className="px-4 py-3 border border-joule text-center">
                Total
              </td>
              {uniqueStatuses.map((status) => (
                <td
                  key={status}
                  className="px-4 py-3 border border-joule text-sm font-semibold text-center"
                >
                  {totals.columnTotals[status]}
                </td>
              ))}
              <td className="px-4 py-3 border border-joule text-sm font-semibold text-center">
                {Object.values(totals.rowTotals).reduce(
                  (acc, cur) => acc + cur,
                  0
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default InventoryDashboard;
