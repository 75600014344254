import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastVisited, setLastVisited] = useState("/inventory");

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        "/api/user/login",
        { email, password },
        { withCredentials: true }
      );
      if (response.data.message === "Logged in") {
        setUser(response.data.user);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      throw new Error(
        error.response?.data.error ||
          "Login failed. Please check your credentials."
      );
    }
  };

  const checkAuth = async () => {
    try {
      const response = await axios.get("/api/user/check-auth", {
        withCredentials: true,
      });
      if (response.data.isAuthenticated) {
        setUser(response.data.user);
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to check authentication:", error);
      setUser(null);
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  };

  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get("/api/csrf-token", {
        withCredentials: true,
      });
      const token = response.data.csrfToken;

      axios.defaults.headers.post["X-CSRF-Token"] = token;
      axios.defaults.headers.put["X-CSRF-Token"] = token;
      axios.defaults.headers.delete["X-CSRF-Token"] = token;
    } catch (error) {
      console.error("Failed to fetch CSRF token", error);
    }
  };

  useEffect(() => {
    checkAuth();
    fetchCsrfToken();
  }, []);

  const updateLastVisited = (path) => {
    setLastVisited(path);
  };

  const logout = async () => {
    try {
      await axios.post("/api/user/logout", {}, { withCredentials: true });
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        lastVisited,
        login,
        logout,
        updateLastVisited,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
