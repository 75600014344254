import React from "react";

const TopBar = () => {
  return (
    <div className="flex w-full justify-center items-center py-8 bg-joule">
      <img
        src={process.env.PUBLIC_URL + "/pictures/joule_logo.png "}
        alt="logo"
        className="object-contain w-40 sm:w-64 h-auto"
      />
    </div>
  );
};

export default TopBar;
