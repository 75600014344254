import React, { useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { DateTime } from "luxon";

ReactModal.setAppElement("#root");

const modalStyles = {
  content: {
    maxWidth: "700px",
    width: "90%",
    margin: "auto",
    padding: "30px",
    borderRadius: "12px",
    border: "4px solid #374151",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.6)",
    overflow: "auto",
    backgroundColor: "#f7fafc", // Lighter background to highlight content
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    zIndex: 1000,
  },
};

const CreateSubscriptionModal = ({
  isOpen,
  toggleModalVisibility,
  data,
  onSuccess,
  onError,
}) => {
  const [serialNumber, setSerialNumber] = useState("");

  // Circuly status
  const [circulyStatus, setCirculyStatus] = useState("");

  // Inventory fields
  const [lastService, setLastService] = useState("");
  const [lastKm, setLastKm] = useState(0);
  const [sku, setSku] = useState("");
  const [operationalStatus, setOperationalStatus] = useState("");

  // State controlling whether user can create subscription
  const [isCreateEnabled, setIsCreateEnabled] = useState(false);

  // We’ll track whether the inventory SKU matches the table SKU
  const [skuMatches, setSkuMatches] = useState(false);

  // NEW: Loading states
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  /**
   * Utility function to compare two SKUs with a special rule:
   * - Exact match => true
   * - If inventory SKU starts with GIA-24 and tableSKU starts with GIA-23,
   *   compare the substring after the first 6 characters ("GIA-2x-").
   * - Otherwise => false
   */
  const compareSku = (inventorySku, tableSku) => {
    if (!inventorySku || !tableSku) return false;

    // Exact match
    if (inventorySku === tableSku) return true;

    // Special case: GIA-24* vs. GIA-23*
    const invPrefix = inventorySku.slice(0, 6); // "GIA-24" or "GIA-23"
    const tblPrefix = tableSku.slice(0, 6);

    if (invPrefix === "GIA-24" && tblPrefix === "GIA-23") {
      // Compare the rest
      return inventorySku.slice(6) === tableSku.slice(6);
    }

    // No match
    return false;
  };

  /**
   * Handle check button:
   * 1) Fetch Circuly data => location_status
   * 2) Fetch inventory data => lastService, lastKm, SKU, operationalStatus
   * 3) Compare SKU from inventory to the table's SKU (data.sku)
   * 4) If location_status === "in stock" and SKUs match => enable create
   */
  const handleCheckSerialNumber = async () => {
    if (!serialNumber) return;

    setIsLoadingCheck(true);

    try {
      let locationStatus = "new"; // Default in case Circuly call fails

      // 1) Try Circuly data
      try {
        const circulyRes = await axios.get(`/api/circuly-data/${serialNumber}`);
        locationStatus = circulyRes.data?.location_status || "N/A";
      } catch (circulyError) {
        console.warn("Circuly call failed — assuming it's a new bike");
        locationStatus = "new";
      }

      setCirculyStatus(locationStatus);

      // 2) Inventory data
      const inventoryRes = await axios.get(`/api/inventory/${serialNumber}`);
      const inventoryItem = inventoryRes.data || {};

      const serviceStatus = inventoryItem?.status?.serviceStatus || {};
      const invModel = inventoryItem?.model || {};
      const invStatus = inventoryItem?.status || {};

      setLastService(serviceStatus.lastService || "");
      setLastKm(serviceStatus.lastKm || 0);
      setSku(invModel.SKU || "");
      setOperationalStatus(invStatus.operationalStatus || "");

      // 3) Compare SKUs
      const tableSku = data.sku || data.productId || "";
      const matched = compareSku(invModel.SKU, tableSku);
      setSkuMatches(matched);

      // 4) Enable create-subscription
      setIsCreateEnabled(
        (locationStatus === "in stock" ||
          locationStatus === "reserved" ||
          locationStatus === "new") &&
          matched
      );
    } catch (error) {
      console.error("Error checking serial number data:", error);
      setCirculyStatus("Error");
      setSkuMatches(false);
      setIsCreateEnabled(false);
    } finally {
      setIsLoadingCheck(false);
    }
  };

  const handleCreateSubscription = async () => {
    // Start loading
    setIsLoadingCreate(true);
    try {
      // Format date as dd-MM-yyyy
      const subscriptionStart = DateTime.fromJSDate(data.tagDate).toFormat(
        "dd-LL-yyyy"
      );

      const payload = {
        id: data.itemId,
        order_id: data.orderId,
        product_id: data.productId,
        serial_number: serialNumber,
        status: "active",
        subscription_start: subscriptionStart,
      };

      const response = await axios.post("/api/circuly-subscriptions", payload);
      console.log("Create Subscription Response:", response.data);

      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(data.itemId);
      }

      toggleModalVisibility();
    } catch (error) {
      console.error("Error creating subscription:", error);

      if (onError && typeof onError === "function") {
        onError("Failed to create subscription. Please try again later.");
      }
    } finally {
      // Stop loading
      setIsLoadingCreate(false);
    }
  };

  const skuColorClass = skuMatches ? "text-green-600" : "text-red-600";

  const isInStockOrReserved =
    circulyStatus === "in stock" || circulyStatus === "reserved";

  const circulyStatusClass =
    circulyStatus === "new"
      ? "text-blue-600"
      : isInStockOrReserved
      ? "text-green-600"
      : "text-red-600";

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleModalVisibility}
      contentLabel="Create Subscription"
      style={modalStyles}
    >
      <div className="space-y-6">
        {/* Title */}
        <div className="text-center mb-2">
          <h2 className="text-gray-900 text-3xl font-bold">
            Create Subscription
          </h2>
          <p className="text-gray-600 mt-1">
            Fill in details to create a new subscription.
          </p>
        </div>

        <hr className="border-t border-gray-300" />

        {/* Serial Number Section */}
        <div className="bg-white rounded shadow-sm p-4">
          <h3 className="text-gray-800 text-lg font-semibold mb-2">
            1. Identify Bike
          </h3>
          <label className="block text-sm font-semibold text-gray-700 mb-1">
            Serial Number
          </label>
          <div className="flex items-center space-x-2 mb-2">
            <input
              type="text"
              className="border-gray-300 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-1 focus:ring-joule"
              placeholder="Enter serial number"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
            />
            <button
              type="button"
              onClick={handleCheckSerialNumber}
              disabled={isLoadingCheck}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-3 text-sm rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
            >
              {isLoadingCheck ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                  Checking...
                </>
              ) : (
                "Check"
              )}
            </button>
          </div>
          <p className="text-xs text-gray-500">
            Enter a valid serial number and click "Check" to fetch data.
          </p>
        </div>

        {/* Circuly & Inventory Info Section */}
        <div className="bg-white rounded shadow-sm p-4">
          <h3 className="text-gray-800 text-lg font-semibold mb-2">
            2. Bike Status & Inventory
          </h3>

          {/* Circuly status */}
          <div className="my-8">
            <span className="font-semibold text-gray-700 mr-2">
              Circuly status:
            </span>
            <span className={circulyStatusClass}>{circulyStatus}</span>
          </div>

          {/* Local Inventory data */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-x-8 text-sm">
            <div>
              <span className="font-semibold">Last Service:</span>{" "}
              <span className="text-gray-700">{lastService || "N/A"}</span>
            </div>
            <div>
              <span className="font-semibold">Last Km:</span>{" "}
              <span className="text-gray-700">{lastKm}</span>
            </div>
            <div>
              <span className="font-semibold">SKU:</span>{" "}
              {/* Conditionally color the SKU based on match */}
              <span className={skuColorClass}>{sku || "N/A"}</span>
            </div>
            <div>
              <span className="font-semibold">Operational Status:</span>{" "}
              <span className="text-gray-700">
                {operationalStatus || "N/A"}
              </span>
            </div>
          </div>
        </div>

        <hr className="border-t border-gray-300" />

        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row justify-center sm:justify-between space-y-4 sm:space-y-0 mt-2">
          <button
            type="button"
            onClick={handleCreateSubscription}
            disabled={!isCreateEnabled || isLoadingCreate}
            className={`w-full sm:w-auto py-2 px-6 font-semibold rounded-lg shadow-md focus:outline-none flex items-center justify-center ${
              isCreateEnabled && !isLoadingCreate
                ? "bg-joule text-white hover:bg-gray-900"
                : "bg-gray-400 text-white cursor-not-allowed"
            }`}
          >
            {isLoadingCreate ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                Creating...
              </>
            ) : (
              "Create Subscription"
            )}
          </button>
          <button
            type="button"
            className="w-full sm:w-auto py-2 px-6 font-semibold rounded-lg shadow-md bg-red-500 text-white hover:bg-red-700 focus:outline-none"
            onClick={toggleModalVisibility}
          >
            Cancel
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default CreateSubscriptionModal;
