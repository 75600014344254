import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../components/AuthProvider";

function Sidebar() {
  const { logout } = useAuth();

  return (
    <div className="w-64 h-screen flex-shrink-0 bg-joule text-white p-6 flex flex-col fixed">
      {" "}
      {/* Image */}
      <img
        src={process.env.PUBLIC_URL + "/pictures/joule_logo.png"}
        alt="Dashboard Icon"
        className="my-12 w-4/5 mx-auto"
      />
      <h2 className="text-xl mt-12 mb-8 text-center">
        {" "}
        <Link
          to="/dashboard"
          className="hover:bg-gray-800 px-2 py-1 rounded text-xl"
        >
          Dashboard
        </Link>
      </h2>
      <ul className="flex-1">
        <li className="mb-6">
          <Link
            to="/inventory"
            className="hover:bg-gray-800 px-2 py-1 rounded text-xl"
          >
            Inventory
          </Link>
          <ul className="mt-3 ml-4">
            <li className="mb-2">
              <Link
                to="/service-parts"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Service parts
              </Link>
            </li>
          </ul>
        </li>

        <li className="mb-6">
          <Link
            to="/todo-list"
            className="hover:bg-gray-800 px-2 py-1 rounded text-xl"
          >
            Todo List
          </Link>
          <ul className="mt-3 ml-4">
            <li className="mb-2">
              <Link
                to="/new-orders"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Orders
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/service-bookings"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Service Bookings
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/returns"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Returns
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/missing-items"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Follow-up Missing Items
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/order-picking-list"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Order Picking List
              </Link>
            </li>
          </ul>
        </li>
        <li className="mb-6">
          <Link
            to="/bike-flow-dashboard"
            className="hover:bg-gray-800 px-2 py-1 rounded text-xl"
          >
            Daily Bike Flow
          </Link>
          <ul className="mt-3 ml-4">
            <li className="mb-2">
              <Link
                to="/incoming-bikes"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Incoming Bikes
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/outgoing-bikes"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Outgoing Bikes
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/transport-booking"
                className="hover:bg-gray-700 px-2 py-1 rounded"
              >
                Transport Booking
              </Link>
            </li>
          </ul>
        </li>
      </ul>
      <button
        onClick={logout}
        className="bg-gray-400 hover:bg-gray-500 text-black py-2 px-4 rounded w-full mt-4"
      >
        Logout
      </button>
    </div>
  );
}

export default Sidebar;
