//When a person clicks on a specific item within the Inventory overview, they will be taken to this page.
//The info on this page will be retrieved from the database using serial_number, the Wanda API and the Circuly API.
//This page will display all the information about the item.
//The page will be displayed in five sections
//The first section is the data we have about the item from our database
//The second section is the data we have about the item from the Wanda API: Get request on the item's wandaID
//The third section is the data we have about the item from the Circuly API: Get request on the item's ID and furthermore the Subscription ID
//The fourth section is a table with all the services the item has been through from the service database
//The fifth section is a list of all the bookings the item has been through from the booking database

// ItemPage.jsx
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import ServiceCell from "../components/serviceRegistration";

const ItemPage = () => {
  const { serialNumber } = useParams();
  const [bikeData, setBikeData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [circulyData, setCirculyData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [loadingServiceId, setLoadingServiceId] = useState(null);

  const [loadingAccessories, setLoadingAccessories] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [operationalStatus, setOperationalStatus] = useState("");
  const [WarehouseSection, setWarehouseSection] = useState("");
  const [itemNote, setItemNote] = useState("");
  const [accessories, setAccessories] = useState({
    charger: 0,
    adapter: 0,
    snakeLock: 0,
    foldingLock: 0,
    lockKey: 0,
    batteryKey: 0,
    reTyreSkins: 0,
    keyChain: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the bike inventory
        const bikeResponse = await axios.get(`/api/inventory/${serialNumber}`);
        setBikeData(bikeResponse.data);

        // Set Operational Status
        setOperationalStatus(
          bikeResponse.data?.status?.operationalStatus || ""
        );

        // Set Warehouse Section
        setWarehouseSection(
          bikeResponse.data?.position?.WarehouseSection || ""
        );

        // Initialize itemNote (handle missing key with empty string)
        setItemNote(bikeResponse.data?.itemNote || "");

        // Set Accessories (Ensure all fields exist, fallback to 0)
        setAccessories({
          charger: bikeResponse.data?.accessories?.charger || 0,
          adapter: bikeResponse.data?.accessories?.adapter || 0,
          snakeLock: bikeResponse.data?.accessories?.snakeLock || 0,
          foldingLock: bikeResponse.data?.accessories?.foldingLock || 0,
          lockKey: bikeResponse.data?.accessories?.lockKey || 0,
          batteryKey: bikeResponse.data?.accessories?.batteryKey || 0,
          reTyreSkins: bikeResponse.data?.accessories?.reTyreSkins || 0,
          keyChain: bikeResponse.data?.accessories?.keyChain || 0,
        });

        // Fetch data from the Circuly API
        const circulyResponse = await axios.get(
          `/api/circuly-data/${serialNumber}`
        );
        setCirculyData(circulyResponse.data);

        // Fetch maintenance data from service DB
        const maintenanceResponse = await axios.get(
          `/api/services/serial/${serialNumber}`
        );
        setMaintenanceData(maintenanceResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [serialNumber]);

  //Function to toggle the serviceregistration modal
  const toggleModal = useCallback((data = null) => {
    if (data) {
      setModalData({ ...data, source: "itemPage" });
    } else {
      setModalData(null);
    }
  }, []);

  //Function to change operational status
  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setOperationalStatus(newStatus);
    try {
      await axios.put(`/api/inventory/${serialNumber}/operationalStatus`, {
        operationalStatus: newStatus,
      });
      setBikeData((prevData) => ({
        ...prevData,
        status: { ...prevData.status, operationalStatus: newStatus },
      }));
    } catch (error) {
      console.error("Error updating operational status:", error);
    }
  };

  //Function to change WarehouseSection
  const handleWarehouseSectionChange = async (e) => {
    const newStatus = e.target.value;
    setWarehouseSection(newStatus);
    try {
      await axios.put(`/api/inventory/${serialNumber}/WarehouseSection`, {
        WarehouseSection: newStatus,
      });
      setBikeData((prevData) => ({
        ...prevData,
        status: { ...prevData.position, WarehouseSection: newStatus },
      }));
    } catch (error) {
      console.error("Error updating operational status:", error);
    }
  };

  // Handler for local state changes of accessory
  const handleAccessoryChange = (e, field) => {
    let newValue = e.target.value;

    // Allow empty input while typing
    if (newValue === "") {
      setAccessories((prev) => ({ ...prev, [field]: "" }));
      return;
    }

    // Ensure it's a valid number
    newValue = parseInt(newValue, 10);
    if (!isNaN(newValue)) {
      setAccessories((prev) => ({ ...prev, [field]: newValue }));
    }
  };

  // Function to save updated accessories to the server
  const updateAccessories = async () => {
    setLoadingAccessories(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      await axios.put(
        `/api/inventory/${serialNumber}/accessories`,
        accessories
      );
      setSuccessMessage("Accessories updated successfully!");

      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(""), 3000);
    } catch (error) {
      setErrorMessage("Error updating accessories. Please try again.");
      console.error("Error updating accessories:", error);

      // Clear error message after 3 seconds
      setTimeout(() => setErrorMessage(""), 3000);
    } finally {
      setLoadingAccessories(false);
    }
  };

  /****** FUNCTION FOR UPDATING ITEM NOTE ******/
  const updateItemNote = async (newNote) => {
    try {
      await axios.put(`/api/inventory/${serialNumber}/itemNote`, {
        itemNote: newNote,
      });
      console.log("Item note updated successfully!");
    } catch (error) {
      console.error("Error updating item note:", error);
    }
  };

  /****** ON-BLUR HANDLER ******/
  const handleNoteBlur = () => {
    // Trigger the PUT request on blur
    updateItemNote(itemNote);
  };

  //Function to trigger an insurance report from Tryg
  const generateInsuranceReport = async () => {
    try {
      // Fetch subscription data from Circuly API on button click (Second API call)
      const subscriptionResponse = await axios.get(
        `/api/circuly-subscription/${circulyData.subscription_id}`
      );
      const contactInfo = subscriptionResponse.data.customer;

      // Log the brand for debugging
      const brand = bikeData.model?.brand?.trim().toLowerCase();

      // Calculate bikeValue based on the brand
      const bikeValue =
        brand === "giant"
          ? "NOK 22 000,-"
          : brand === "superior" || brand === "white"
          ? "NOK 15 000,-"
          : brand === "riese & müller"
          ? "NOK 40 000,-"
          : "Unknown";

      // Prepare insurance data with fetched subscription info
      const insuranceData = {
        customerFirstName: contactInfo.address.shipping.first_name,
        customerLastName: contactInfo.address.shipping.last_name,
        customerAddressStreet: contactInfo.address.shipping.street,
        customerAddressPostalCode: contactInfo.address.shipping.postal_code,
        customerAddressCity: contactInfo.address.shipping.city,
        customerEmail: contactInfo.email,
        customerPhone: contactInfo.phone,
        bikeModel: `${bikeData.model?.brand} ${bikeData.model?.year} ${bikeData.model?.type}`,
        bikeValue, // Set the calculated bikeValue
        bikePurchaseMonth: `April ${bikeData.model?.year}`,
        bikeFrameNumber: bikeData.frameNumber,
        serialNumber: serialNumber,
      };

      // Load the .docx template file from the public folder
      const templateResponse = await fetch(
        "/Skademeldingsskjema_Tryg_Tyveriforsikring_Template.docx"
      );
      const templateArrayBuffer = await templateResponse.arrayBuffer();
      const zip = new PizZip(templateArrayBuffer);

      // Load the docxtemplater with the zip containing the docx file
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      // Set the data into the template
      doc.setData(insuranceData);

      try {
        // Render the document (fill the variables)
        doc.render();
      } catch (error) {
        console.error("Error rendering document:", error);
        return;
      }

      // Generate the filled-out DOCX file
      const generatedDocument = doc.getZip().generate({ type: "blob" });

      // Create the filename based on customer info
      const fileName = `${insuranceData.customerFirstName} ${insuranceData.customerLastName}, Skademeldingsskjema Tryg Tyveriforsikring Joule 0362-2.docx`;

      // Trigger download of the file without saving
      saveAs(generatedDocument, fileName);
    } catch (error) {
      console.error("Error generating insurance report:", error);
    }
  };

  //Function to reload data after a maintenance has been registered
  const reloadData = async () => {
    try {
      const bikeResponse = await axios.get(`/api/inventory/${serialNumber}`);
      setBikeData(bikeResponse.data);

      const maintenanceResponse = await axios.get(
        `/api/services/serial/${serialNumber}`
      );
      setMaintenanceData(maintenanceResponse.data);

      console.log("Data reloaded successfully.");
    } catch (error) {
      console.error("Error reloading data:", error);
    }
  };

  // Function to update inventory status on last service date and km after a deletion
  const updateInventoryAfterDelete = async (deletedServiceId) => {
    try {
      // Exclude the deleted service and find the latest service based on date
      const remainingServices = maintenanceData.filter(
        (service) => service._id !== deletedServiceId
      );

      if (remainingServices.length > 0) {
        // Sort by date in descending order and select the latest service
        const latestService = remainingServices.sort(
          (a, b) => new Date(b.serviceDate) - new Date(a.serviceDate)
        )[0];

        const updatePayload = {
          lastService: latestService.serviceDate,
          lastKm: latestService.km,
        };

        await axios.put(`/api/inventory/${serialNumber}`, updatePayload);
        console.log(
          "Inventory item updated with the latest remaining service record."
        );
      } else {
        // Set fields to null if no records remain
        await axios.put(`/api/inventory/${serialNumber}`, {
          lastService: null,
          lastKm: null,
        });
        console.log(
          "Inventory item reset due to deletion of the last remaining record."
        );
      }
    } catch (error) {
      console.error("Error updating inventory after delete:", error);
    }
  };

  // Function to delete a service
  const handleDeleteService = async (serviceId) => {
    setLoadingServiceId(serviceId); // Start loading for this service
    try {
      // Step 1: Delete the service
      await axios.delete(`/api/services/${serviceId}`);

      // Step 2: Update inventory after deletion
      await updateInventoryAfterDelete(serviceId);

      // Step 3: Reload the latest bike and maintenance data
      await reloadData();
    } catch (error) {
      console.error("Error deleting service:", error);
    } finally {
      setLoadingServiceId(null); // Clear loading state after completion
    }
  };

  if (!bikeData) {
    return <div>Loading bike data...</div>;
  }

  return (
    <div className="p-4 mt-4 flex-1">
      {" "}
      {/* Adjusted for responsive layout */}
      <div className="flex flex-col lg:flex-row gap-4">
        {/* Left Side: Database Information */}
        <div className="flex-grow bg-white shadow-md rounded px-8 pt-1 pb-4 mb-4">
          <h2 className="text-2xl font-semibold text-gray-900 border-b-2 border-gray-300 pb-2 mb-6">
            Database Information - Serial Number: {serialNumber}
          </h2>
          <table className="min-w-full table-auto">
            <tbody>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Frame Number</th>
                <td className="px-4 py-2">{bikeData.frameNumber}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Brand</th>
                <td className="px-4 py-2">{bikeData.model?.brand}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Type</th>
                <td className="px-4 py-2">{bikeData.model?.type}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Year</th>
                <td className="px-4 py-2">{bikeData.model?.year}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Size</th>
                <td className="px-4 py-2">{bikeData.model?.size}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Innsteg</th>
                <td className="px-4 py-2">{bikeData.model?.innsteg}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">SKU</th>
                <td className="px-4 py-2">{bikeData.model?.SKU}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Lock Key</th>
                <td className="px-4 py-2">{bikeData.keyNumber?.lockKey}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Battery Key</th>
                <td className="px-4 py-2">{bikeData.keyNumber?.batteryKey}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Operational Status</th>
                <td className="px-4 py-2">
                  <select
                    value={operationalStatus}
                    onChange={handleStatusChange}
                    className="border p-1 rounded"
                  >
                    <option value="in stock">in stock</option>
                    <option value="marketing">marketing</option>
                    <option value="new">new</option>
                    <option value="ready for customer">
                      ready for customer
                    </option>
                    <option value="repair-booked">repair-booked</option>
                    <option value="repair-new order">repair-new order</option>
                    <option value="repair-returned">repair-returned</option>
                    <option value="stolen">stolen</option>
                    <option value="with customer">with customer</option>
                    <option value="summer freeze">summer freeze</option>
                    <option value="winter freeze">winter freeze</option>
                    <option value="bought out">bought out</option>
                  </select>
                </td>
              </tr>

              <tr className="border-b">
                <th className="px-4 py-2 text-left">Last Service</th>
                <td className="px-4 py-2">
                  {bikeData.status?.serviceStatus?.lastService}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Last KM</th>
                <td className="px-4 py-2">
                  {bikeData.status?.serviceStatus?.lastKm}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Battery Capacity</th>
                <td className="px-4 py-2">
                  {bikeData.status?.batteryStatus?.batteryCapacity}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Battery Charge Cycles</th>
                <td className="px-4 py-2">
                  {bikeData.status?.batteryStatus?.batteryChargeCycles}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Country</th>
                <td className="px-4 py-2">{bikeData.position?.Country}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">City</th>
                <td className="px-4 py-2">{bikeData.position?.City}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Warehouse</th>
                <td className="px-4 py-2">{bikeData.position?.Warehouse}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Warehouse Section</th>
                <td className="px-4 py-2">
                  <select
                    value={WarehouseSection}
                    onChange={handleWarehouseSectionChange}
                    className="border p-1 rounded"
                  >
                    {[
                      "L1",
                      "L2",
                      "L3",
                      "L4",
                      "L5",
                      "L6",
                      "L7",
                      "L8",
                      "R1",
                      "S1",
                      "S2",
                      "S3",
                      "G1",
                      "G2",
                      "G3",
                      "G4",
                      "G5",
                      "U1",
                      "U2",
                    ].map((section) => (
                      <option key={section} value={section}>
                        {section}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Right Side: Circuly, Accesory and note information */}
        <div className="flex-grow bg-white shadow-md rounded px-8 pt-1 pb-4 mb-4">
          {/* Accessories & Note Section */}
          <div className="mb-11 flex flex-col lg:flex-row gap-6 items-stretch">
            {/* LEFT COLUMN: Accessories */}
            <div className="w-full lg:w-1/2">
              <h2 className="text-2xl font-semibold text-gray-900 border-b-2 border-gray-300 pb-2 mb-3">
                Accessories
              </h2>
              <table className="min-w-full table-auto mb-4 border rounded-lg shadow-sm border-separate">
                <tbody>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Charger</th>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="border p-1 rounded w-16"
                        value={
                          accessories.charger === "" ? "" : accessories.charger
                        }
                        onChange={(e) => handleAccessoryChange(e, "charger")}
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Adapter</th>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="border p-1 rounded w-16"
                        value={
                          accessories.adapter === "" ? "" : accessories.adapter
                        }
                        onChange={(e) => handleAccessoryChange(e, "adapter")}
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Snake Lock</th>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="border p-1 rounded w-16"
                        value={
                          accessories.snakeLock === ""
                            ? ""
                            : accessories.snakeLock
                        }
                        onChange={(e) => handleAccessoryChange(e, "snakeLock")}
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Folding Lock</th>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="border p-1 rounded w-16"
                        value={
                          accessories.foldingLock === ""
                            ? ""
                            : accessories.foldingLock
                        }
                        onChange={(e) =>
                          handleAccessoryChange(e, "foldingLock")
                        }
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Lock Key</th>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="border p-1 rounded w-16"
                        value={
                          accessories.lockKey === "" ? "" : accessories.lockKey
                        }
                        onChange={(e) => handleAccessoryChange(e, "lockKey")}
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Battery Key</th>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="border p-1 rounded w-16"
                        value={
                          accessories.batteryKey === ""
                            ? ""
                            : accessories.batteryKey
                        }
                        onChange={(e) => handleAccessoryChange(e, "batteryKey")}
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">ReTyre Skins</th>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="border p-1 rounded w-16"
                        value={
                          accessories.reTyreSkins === ""
                            ? ""
                            : accessories.reTyreSkins
                        }
                        onChange={(e) =>
                          handleAccessoryChange(e, "reTyreSkins")
                        }
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Key chain</th>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="border p-1 rounded w-16"
                        value={
                          accessories.keyChain === ""
                            ? ""
                            : accessories.keyChain
                        }
                        onChange={(e) => handleAccessoryChange(e, "keyChain")}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Success and Error Messages */}
              {successMessage && (
                <p className="text-green-600 text-sm">{successMessage}</p>
              )}
              {errorMessage && (
                <p className="text-red-600 text-sm">{errorMessage}</p>
              )}

              {/* Save Button with Loading State */}
              <button
                onClick={updateAccessories}
                className={`bg-joule text-white px-3 py-1 rounded hover:bg-jouledark text-sm mt-2 ${
                  loadingAccessories ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loadingAccessories}
              >
                {loadingAccessories ? "Saving..." : "Save Accessories"}
              </button>
            </div>

            {/* RIGHT COLUMN: Item Note */}
            <div className="w-full lg:w-1/2">
              <h2 className="text-2xl font-semibold text-gray-900 border-b-2 border-gray-300 pb-2 mb-3">
                Bike Note
              </h2>
              <textarea
                className="border p-2 w-full rounded h-40"
                placeholder="Enter any notes for this bike..."
                value={itemNote}
                onChange={(e) => setItemNote(e.target.value)}
                onBlur={handleNoteBlur} // Trigger update on blur
              />
              <p className="text-sm text-gray-500 mt-1">
                Changes are saved when you click outside the text box.
              </p>
            </div>
          </div>

          {circulyData && (
            <div className="mb-4">
              {/* Flex container to align header and button on the same line */}
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold text-gray-900 border-b-2 border-gray-300 pb-2">
                  Circuly Data
                </h2>
                {/* Smaller button aligned to the right */}
                <button
                  className="bg-joule text-white px-2 py-1 rounded hover:bg-jouledark text-sm"
                  onClick={generateInsuranceReport}
                >
                  Generate Insurance Report
                </button>
              </div>
              <table className="min-w-full table-auto">
                <tbody>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Subscription ID</th>
                    <td className="px-4 py-2">{circulyData.subscription_id}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Rental Status</th>
                    <td className="px-4 py-2">{circulyData.location_status}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Customer ID</th>
                    <td className="px-4 py-2">{circulyData.customer_id}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Customer Name</th>
                    <td className="px-4 py-2">{circulyData.customer_name}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Last Updated</th>
                    <td className="px-4 py-2">{circulyData.updated_at}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {/* Maintenance Records Section */}
      <div className="bg-white shadow-md rounded px-8 pt-1 pb-1 mb-4 mt-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-900 border-b-2 border-gray-300 pb-2">
            Maintenance Records
          </h2>
          {(operationalStatus === "in stock" ||
            operationalStatus === "winter freeze") && (
            <button
              className="bg-joule text-white px-4 py-2 rounded hover:bg-jouledark text-sm"
              onClick={() => toggleModal({ serialNumber })}
              style={{ marginLeft: "auto" }}
            >
              Register Service
            </button>
          )}
        </div>

        <table className="min-w-full table-auto">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 text-left">Date</th>
              <th className="px-4 py-2 text-left">Type</th>
              <th className="px-4 py-2 text-left">Service Category</th>
              <th className="px-4 py-2 text-left">KM/ODO</th>
              <th className="px-4 py-2 text-left">Parts Used</th>
              <th className="px-4 py-2 text-left">Note</th>
            </tr>
          </thead>
          <tbody>
            {maintenanceData.length > 0 ? (
              maintenanceData.map((service, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">
                    {new Date(service.serviceDate).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2">{service.serviceType}</td>
                  <td className="px-4 py-2">{service.serviceCategory}</td>
                  <td className="px-4 py-2">{service.km}</td>
                  <td className="px-4 py-2">
                    <ul className="list-disc list-inside">
                      {service.partsUsed.map((part, partIndex) => (
                        <li key={partIndex}>
                          {part.productDescription}{" "}
                          <span className="text-gray-500">
                            ({part.quantity})
                          </span>
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="px-4 py-2">{service.note}</td>
                  <td className="px-4 py-2 text-right">
                    <button
                      onClick={() => handleDeleteService(service._id)}
                      disabled={loadingServiceId === service._id}
                      className={`bg-red-500 text-white font-bold px-3 py-1 rounded hover:bg-red-700 ${
                        loadingServiceId === service._id
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      {loadingServiceId === service._id ? "Deleting..." : "X"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="px-4 py-2 text-center">
                  No maintenance records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Service Registration Modal */}
      {modalData && (
        <ServiceCell
          isOpen={!!modalData}
          data={modalData}
          source={modalData.source}
          toggleModalVisibility={() => toggleModal()}
          reloadData={reloadData} // Pass the reloadData function
        />
      )}
    </div>
  );
};

export default ItemPage;
