import React, { useEffect, useState, useMemo } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Table from "../components/tableComponent";
import { format, parseISO } from "date-fns";
import nbLocale from "date-fns/locale/nb";

const Inventory = () => {
  const [data, setData] = useState([]);
  const [filterInput, setFilterInput] = useState(""); // User's input for filtering
  const [loading, setLoading] = useState(true);
  const [filterCategory, setFilterCategory] = useState(""); // To hold filter type, e.g. "serialNumber", "frameNumber", etc.

  //Code for the itemPage link
  const navigate = useNavigate(); // Hook to navigate

  // Custom row click handler
  const onRowClick = (row) => {
    console.log("Row clicked, serial number:", row.values.serialNumber);
    navigate(`/item-page/${row.values.serialNumber}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
        filter: "equals",
      },
      {
        Header: "Frame Number",
        accessor: "frameNumber",
      },
      {
        Header: "Brand",
        accessor: "model.brand",
      },
      {
        Header: "Type",
        accessor: "model.type",
      },
      {
        Header: "Year",
        accessor: "model.year",
      },
      {
        Header: "Size",
        accessor: "model.size",
      },
      {
        Header: "Innsteg",
        accessor: "model.innsteg",
      },
      {
        Header: "SKU",
        accessor: "model.SKU",
      },
      {
        Header: "Lock Key",
        accessor: "keyNumber.lockKey", // Adjust the accessor to point to the nested lockKey property
      },
      {
        Header: "Battery Key",
        accessor: "keyNumber.batteryKey", // Adjust the accessor to point to the nested batteryKey property
      },
      {
        Header: "Position",
        accessor: "position.WarehouseSection",
      },
      {
        Header: "Operational Status",
        accessor: "status.operationalStatus",
      },
      {
        Header: "Service Date",
        accessor: (row) => {
          if (row.status?.operationalStatus === "stolen") return null;
          if (!row.status?.serviceStatus?.lastService) return null;

          // Return raw Date object for sorting
          return parseISO(row.status.serviceStatus.lastService);
        },
        Cell: ({ value }) => {
          // Display formatted date
          if (!value) return "Ingen dato";
          return format(value, "d. MMMM yyyy", { locale: nbLocale });
        },
        sortType: (rowA, rowB, columnId) => {
          const dateA = rowA.values[columnId];
          const dateB = rowB.values[columnId];

          if (!dateA) return 1;
          if (!dateB) return -1;

          return dateA > dateB ? 1 : -1;
        },
      },

      {
        Header: "Service KM",
        accessor: "status.serviceStatus.lastKm",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setFilter,
    rows,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await axios.get("/api/inventory");
        setData(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching inventory:", error);
        setLoading(false);
      }
    };
    fetchInventory();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilterInput(value);
    if (filterCategory) {
      setFilter(filterCategory, value);
    }
  };

  //Function to export inventory data to csv
  const exportInventoryToCsv = () => {
    // Header row, manually specifying nested paths
    const headers = [
      "Serial Number",
      "Frame Number",
      "Lock Key",
      "Battery Key",
      "Brand",
      "Type",
      "Year",
      "Size",
      "Innsteg",
      "SKU",
      "Operational Status",
      "Service Status - Last Service",
      "Service Status - Last Km",
      "Battery Status - Battery Capacity",
      "Battery Status - Battery Charge Cycles",
      "Country",
      "City",
      "Warehouse",
      "WarehouseSection",
      "Wanda ID",
    ];

    // Convert data to CSV rows
    const csvRows = data.map((row) => {
      // Extracting and formatting nested data into a flat structure for CSV
      return [
        row.serialNumber,
        row.frameNumber,
        row.keyNumber?.lockKey,
        row.keyNumber?.batteryKey,
        row.model?.brand,
        row.model?.type,
        row.model?.year,
        row.model?.size,
        row.model?.innsteg,
        row.model?.SKU,
        row.status?.operationalStatus,
        row.status?.serviceStatus?.lastService,
        row.status?.serviceStatus?.lastKm,
        row.status?.batteryStatus?.batteryCapacity,
        row.status?.batteryStatus?.batteryChargeCycles,
        row.position?.Country,
        row.position?.City,
        row.position?.Warehouse,
        row.position?.WarehouseSection,
        row.wandaID,
      ].join(",");
    });

    // Joining headers and rows to form the CSV string
    const csvString = [headers.join(","), ...csvRows].join("\n");

    // Blob and download handling
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "inventory.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Function to export service history to CSV
  const exportMaintenanceToCsv = async () => {
    try {
      const response = await axios.get("/api/services");
      const services = response.data;

      // Define CSV headers
      const headers = [
        "Service Type",
        "Serial Number",
        "Order ID",
        "Subscription ID",
        "Service Date",
        "KM",
        "Note",
        "Parts Used",
      ];

      // Convert data to CSV rows
      const csvRows = services.map((service) => {
        // Convert the partsUsed array to a string
        const partsUsedString = service.partsUsed
          .map((part) => {
            // Create a concise representation for each part
            // You can customize this as needed
            return `${part.productNo || ""} - ${
              part.productDescription || ""
            } - Qty: ${part.quantity || 1}`;
          })
          .join(" | "); // join all parts with a " | "

        return [
          service.serviceType,
          service.serialNumber,
          service.orderId,
          service.subscriptionId,
          new Date(service.serviceDate).toLocaleDateString(),
          service.km,
          // safely handle empty notes
          service.note || "",
          partsUsedString,
        ].join(",");
      });

      // Join headers and rows into CSV format
      const csvString = [headers.join(","), ...csvRows].join("\n");

      // Create Blob and trigger download
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "maintenance.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting maintenance data:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow p-4">
      <h2 className="text-2xl font-semibold mb-4">Inventory</h2>

      {/* Filter Inputs and Export Button */}
      <div className="flex justify-between mb-4">
        <div>
          {/* Dropdown to select filter category/column */}
          <select
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="border p-2 rounded mr-2 focus:outline-none focus:ring-2 focus:ring-indigo-400 mb-2"
          >
            <option value="">Select Filter Category...</option>
            {columns.map((column) => (
              <option value={column.accessor} key={column.accessor}>
                {column.Header}
              </option>
            ))}
          </select>

          {/* Text input for entering filter values */}
          <input
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={"Enter filter value..."}
            className="border p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-400"
            style={{ display: "inline-block", maxWidth: "calc(100% - 220px)" }} // Adjust width as necessary
          />
        </div>

        {/* Export Buttons Section */}
        <div className="flex justify-end space-x-2">
          {/* Export Maintenance to CSV Button */}
          <button
            onClick={exportMaintenanceToCsv}
            className="bg-gray-500 hover:bg-gray-700 text-white py-1 px-4 rounded"
          >
            Export maintenance to CSV
          </button>

          {/* Export Inventory to CSV Button */}
          <button
            onClick={exportInventoryToCsv}
            className="bg-gray-500 hover:bg-gray-700 text-white py-1 px-4 rounded"
          >
            Export inventory to CSV
          </button>
        </div>
      </div>

      {/* Table section */}
      <Table
        columns={columns}
        data={page}
        onRowClick={onRowClick}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalFilteredRows={rows.length}
      />
    </div>
  );
};

export default Inventory;
